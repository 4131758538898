import React, { Component } from "react";
import { Route, Switch ,BrowserRouter as Router } from "react-router-dom";
import NotificationSystem from "react-notification-system";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import { style } from "variables/Variables.jsx";

import routes from "routes.js";
import sidebarMenu from "routes.js";

// import image from "assets/img/sidebar-3.jpg";
import ColorList from "views/color/ColorList.jsx";
import CompanyInfo from "views/companyInfo/CompanyInfo";
import TermList from "views/term/TermList";
import TermDetail from "views/term/TermDetail";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      fixedClasses: "dropdown", // default 닫힘
    };
  }
  handleNotificationClick = (position) => {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
  };
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
          if(prop.component===ColorList ||
            prop.component===CompanyInfo
            ){
              return (
                    <Route
                      path={prop.layout + prop.path}
                      render={(props) => (
                        <prop.component
                          {...props}
                          handleClick={this.handleNotificationClick}
                        />
                      )}
                      key={key}
                    />
                  );
            }else{
          //상세정보 조회시 path 맨 뒤에 id값이 붙음
            return (
              <Route
                path={prop.layout + prop.path + "/:value"}
                render={(props) => (
                  <prop.component
                    {...props}
                    handleClick={this.handleNotificationClick}
                  />
                )}
                key={key}
              />
            );
    }
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    var _notificationSystem = this.refs.notificationSystem;
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  render() {
    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar {...this.props} routes={routes} />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar
            onLogout={this.props.onLogout}
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          {/* <Router forceRefresh> */}
            <Switch>{this.getRoutes(routes)}</Switch>
          {/* </Router> */}
          <Footer />
        </div>
      </div>
    );
  }
}

export default Admin;
