import React, { Component } from "react";
import { Tooltip, Col, Row, OverlayTrigger } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import avatar from "assets/img/faces/face-0.jpg";

export class Tasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
  }

  render() {
    console.log(this.props);
    const tasks_title = this.props.taskList;
    var tasks = [];
    var number;
    for (var i = 0; i < tasks_title.length; i++) {
      // console.log(tasks_title);
      const taskNo = i;
      number = "checkbox" + i;
      tasks.push(
        // <tr key={i}>
        // <td>
        //   <Checkbox number={number} checked={true} />
        // </td>
        // <td>{tasks_title[i].id}</td>
        //  <td>
        //  <div className="all-icons">
        // <Col lg={3} md={3} sm={4} xs={6} className="font-icon-list">
        <div className="all-icons">
          <Col
            lg={3}
            md={3}
            sm={4}
            xs={6}
            className="font-icon-list"
            style={{ width: "20%" }}
          >
            <div
              className="font-icon-detail"
              style={{
                padding: "0px",
                borderRadius: "15px",
                overflow: "hidden",
                position: "relative",
              }}
              key={i}
            >
              {this.props.type === "contentHighlight" && (
                <>
                  <i className={tasks_title[i].id}>
                    <video
                      controls
                      className="video"
                      width="100%"
                      height="100%"
                      src={tasks_title[i].thumbnail}
                    />
                  </i>
                  {/* {tasks_title[i].id} */}
                </>
              )}
              {this.props.type === "contentJob" &&
                tasks_title[i].profileThumbnail != "" && (
                  <>
                    <i className={tasks_title[i].id}>
                      <img
                        width="100%"
                        height="100%"
                        src={tasks_title[i].profileThumbnail}
                      />
                    </i>
                    {tasks_title[i].nickname}
                  </>
                )}
              {this.props.type === "contentJob" &&
                tasks_title[i].profileThumbnail === "" && (
                  <>
                    <i className={tasks_title[i].id}>
                      <img width="100%" height="100%" src={avatar} />
                    </i>
                    {tasks_title[i].nickname}
                  </>
                )}

              {/* <Checkbox number={number} checked={true} /> */}

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="remove_tooltip">Remove</Tooltip>}
              >
                <Button
                  bsStyle="danger"
                  simple
                  type="button"
                  bsSize="xs"
                  name={tasks_title[i].id}
                  onClick={(e) => this.props.onClick(taskNo)}
                >
                  <i className="fa fa-times" />
                </Button>
              </OverlayTrigger>
            </div>
          </Col>
        </div>
        // </td>
        // <td className="td-actions text-right">
        //   <OverlayTrigger
        //     placement="top"
        //     overlay={<Tooltip id="remove_tooltip">Remove</Tooltip>}
        //   >
        //     <Button
        //       bsStyle="danger"
        //       simple
        //       type="button"
        //       bsSize="xs"
        //       name={tasks_title[i].id}
        //       onClick={(e) => this.props.onClick(taskNo)}
        //     >
        //       <i className="fa fa-times" />
        //     </Button>
        //   </OverlayTrigger>
        // </td>
        // </tr>
      );
    }
    return (
      <Row>{tasks}</Row>

      // <div className="all-icons">
      //   <Col lg={3} md={3} sm={4} xs={6} className="font-icon-list">
      //     {tasks}
      //   </Col>
      // </div>
    );
    // <tbody>{tasks}</tbody>;
  }
}

export default Tasks;
