import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { useHistory } from "react-router-dom";
// import { UserCard } from "views/users/node_modules/components/UserCard/UserCard.jsx.js";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  fetchData,
  editFormData,
  addFileUpload,
  deleteData,
} from "modules/api.js";
import { useStyles } from "./style.jsx";
import Moment from "moment";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-ko-KR";

function CompanyInfo(props) {
  const [input, setInput] = useState();
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState();
  const [editorText, setEditorText] = useState("");
  const [galleryList, setGalleryList] = useState();
  const [snsList, setSnsList] = useState([]);
  const [changeSns, setChangeSns] = useState();
  const reload = () => {
    (async function anyNameFunction() {
      const result = await fetchData(`user/companyInfo`);
      console.log(result);
      setInput(result.data);
      setData(result.data);
      setEditorText(result.data.contents);
      setGalleryList(result.data.gallery);
      setSnsList(result.data.BrandSns);
    })();
  };

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handleSnsChange = (e, item, index) => {
    let newList = snsList;
    item = { ...item, link: e.target.value };
    newList[index] = item;
    setSnsList(newList);
  };

  const changeProfileImage = (e) => {
    console.log("프로필파일");
    let reader = new FileReader();
    let imageFile = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        profileImageFile: imageFile,
        profileImage: reader.result,
      });
    };
    reader.readAsDataURL(imageFile);
  };

  const changeBusinessImage = (e) => {
    console.log("사업자등록증파일");
    let reader = new FileReader();
    let imageFile = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        businessNumImageFile: imageFile,
        businessNumImage: reader.result,
      });
    };
    reader.readAsDataURL(imageFile);
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
      // return false;
    }
    reload();
  }, []);

  const onSubmit = () => {
    if (!input.name || input.name == "") {
      alert("상호를 입력해주세요");
      return false;
    }
    if (!input.owner || input.owner == "") {
      alert("대표를 입력해주세요");
      return false;
    }
    if (!input.address || input.address == "") {
      alert("주소를 입력해주세요");
      return false;
    }
    if (!input.businessNum || input.businessNum == "") {
      alert("사업자번호를 입력해주세요");
      return false;
    }
    if (!input.mailOrderSalesNum || input.mailOrderSalesNum == "") {
      alert("통신판매업번호를 입력해주세요");
      return false;
    }
    if (!input.homepage || input.homepage == "") {
      alert("홈페이지 주소를 입력해주세요");
      return false;
    }
    if (!input.csEmail || input.csEmail == "") {
      alert("고객센터 이메일 주소를 입력해주세요");
      return false;
    }
    if (!input.csPhone || input.csPhone == "") {
      alert("고객센터 전화번호를 입력해주세요");
      return false;
    }

    const bodyFormData = new FormData();
    bodyFormData.set("name", input.name);
    bodyFormData.set("owner", input.owner);
    bodyFormData.set("address", input.address);
    bodyFormData.set("businessNum", input.businessNum);
    bodyFormData.set("mailOrderSalesNum", input.mailOrderSalesNum);
    bodyFormData.set("homepage", input.homepage);
    bodyFormData.set("csEmail", input.csEmail);   
    bodyFormData.set("csPhone",  input.csPhone);
  
    editFormData(`admin/companyInfo`, bodyFormData).then(function (
      response
    ) {
      if (response.result) {
        alert("서비스 정보 수정이 완료되었습니다.");
        history.push(`/admin/companyInfo`);
      } else {
        alert(response);
      }
    });
  };



  return (
    <div className="content">
      <Grid fluid>
        {data && (
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col md={7}>
              <Card
                title="회사정보"
                content={
                  <form onSubmit={onSubmit}>
                    <FormInputs
                      ncols={["col-md-6", "col-md-3", "col-md-3"]}
                      properties={[         
                        {
                          label: "상호",
                          type: "text",
                          bsClass: "form-control",
                          name: "name",
                          // disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.name || "",
                          style: { cursor: "initial" },
                        },   
                        {
                          label: "대표",
                          type: "text",
                          bsClass: "form-control",
                          name: "owner",
                          // disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.owner || "",
                          style: { cursor: "initial" },
                        },             
                        {
                          label: "수정일",
                          type: "text",
                          bsClass: "form-control",
                          name: "updatedAt",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.updatedAt
                            ? Moment(input.updatedAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "주소",
                          type: "text",
                          bsClass: "form-control",
                          name: "address",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.address || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "사업자번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "businessNum",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.businessNum || "",
                          style: { cursor: "initial" },
                        },
                        
                      ]}
                    />
                     <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "통신판매업신고번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "mailOrderSalesNum",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.mailOrderSalesNum || "",
                          style: { cursor: "initial" },
                        },
                       
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "홈페이지",
                          type: "text",
                          bsClass: "form-control",
                          name: "homepage",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.homepage || "",
                          style: { cursor: "initial" },
                        },
                       
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-6","col-md-6"]}
                      properties={[
                        {
                          label: "고객센터 전화번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "csPhone",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.csPhone || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "고객센터 이메일",
                          type: "text",
                          bsClass: "form-control",
                          name: "csEmail",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.csEmail || "",
                          style: { cursor: "initial" },
                        },
                       
                      ]}
                    />
                  
                    <Button
                      bsStyle="info"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={onSubmit}
                    >
                      수정
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
            {/* <Col md={3}>
              {input && (
                <>
                  <Card
                    title="대표 이미지"
                    content={
                      <>
                        <label
                          className="fileInputStyle"
                          htmlFor="fileInput3"
                          style={{
                            color: "rgba(0, 0, 0, 0.87)",
                            marginBottom: "15px",
                            fontSize: "14px",
                          }}
                        >
                          업로드
                        </label>
                        <input
                          id="fileInput3"
                          type="file"
                          name="profileImageFile"
                          style={{ marginBottom: "10px", display: "none" }}
                          onChange={changeProfileImage}
                          accept=".jpg, .jpeg, .png, .bmp"
                        />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            style={{
                              maxWidth: "90%",
                              maxHeight: "350px",
                              borderRadius: "5px",
                              verticalAlign: "middle",
                            }}
                            src={input.profileImage || ""}
                          />
                        </div>
                      </>
                    }
                  />
                  <Card
                    title="사업자등록증 이미지"
                    content={
                      <>
                        <label
                          className="fileInputStyle"
                          htmlFor="fileInput2"
                          style={{
                            color: "rgba(0, 0, 0, 0.87)",
                            marginBottom: "15px",
                            fontSize: "14px",
                          }}
                        >
                          업로드
                        </label>
                        <input
                          id="fileInput2"
                          type="file"
                          name="businessNumImageFile"
                          style={{ marginBottom: "10px", display: "none" }}
                          onChange={changeBusinessImage}
                          accept=".jpg, .jpeg, .png, .bmp"
                        />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            style={{
                              maxWidth: "90%",
                              maxHeight: "350px",
                              borderRadius: "5px",
                              verticalAlign: "middle",
                            }}
                            src={input.businessNumImage || ""}
                          />
                        </div>
                      </>
                    }
                  />
                </>
              )}
            </Col>
           */}
          </Row>
        )}
      </Grid>

    </div>
  );
}

export default CompanyInfo;
