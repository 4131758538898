import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { useHistory } from "react-router-dom";
// import { UserCard } from "views/users/node_modules/components/UserCard/UserCard.jsx.js";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  fetchData,
  editFormData,
  addFileUpload,
  deleteData,
} from "modules/api.js";
import { useStyles } from "./style.jsx";
import Moment from "moment";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-ko-KR";

function TermDetail(props) {
  const [input, setInput] = useState();
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState();
  const [editorText, setEditorText] = useState("");
  const [galleryList, setGalleryList] = useState();
  const [snsList, setSnsList] = useState([]);
  const [changeSns, setChangeSns] = useState();
  const reload = () => {
    (async function anyNameFunction() {
      const result = await fetchData(`user/term/${props.match.params.value}`);
      console.log(result);
      setInput(result.data);
      setData(result.data);
      setEditorText(result.data.contents);
      setGalleryList(result.data.gallery);
      setSnsList(result.data.BrandSns);
    })();
  };

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handleSnsChange = (e, item, index) => {
    let newList = snsList;
    item = { ...item, link: e.target.value };
    newList[index] = item;
    setSnsList(newList);
  };

  const changeProfileImage = (e) => {
    console.log("프로필파일");
    let reader = new FileReader();
    let imageFile = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        profileImageFile: imageFile,
        profileImage: reader.result,
      });
    };
    reader.readAsDataURL(imageFile);
  };

  const changeBusinessImage = (e) => {
    console.log("사업자등록증파일");
    let reader = new FileReader();
    let imageFile = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        businessNumImageFile: imageFile,
        businessNumImage: reader.result,
      });
    };
    reader.readAsDataURL(imageFile);
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
      // return false;
    }
    reload();
  }, []);

  const onSubmit = () => {
    
    const bodyFormData = new FormData();
    bodyFormData.set("contents", editorText);
    editFormData(`admin/term/${input.id}`, bodyFormData).then(function (
      response
    ) {
      console.log(response);
      if (response.result) {
        alert("약관 정보 수정이 완료되었습니다.");
        history.push(`/admin/termList/1`);
      } else {
        alert(response);
      }
    });
  };

  /**
   *
   * 서머노트 관련 함수
   */
  const onChange = (content) => {
    setEditorText(content);
  };

  const onImageUpload = (images, insertImage) => {
    console.log("onImageUpload", images);
    const bodyFormData = new FormData();
    for (let i = 0; i < images.length; i++) {
      bodyFormData.set("files", images[i]);
      addFileUpload(`admin/image-insert`, bodyFormData).then(function (
        response
      ) {
        console.log(response.data.filePath);
        insertImage(response.data.filePath);
      });
    }
  };

  const onInit = (note) => {
    note.reset();
    const regex = /(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/i;
    if (data && data.contents) {
      console.log("tset", data.contents.match(regex));
      if (data.contents.match(regex) !== null) {
        note.replace(data && data.contents);
      } else {
        note.insertText(data && data.contents);
      }
    }
  };

  return (
    <div className="content">
      <Grid fluid>
        {data && (
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col md={7}>
              <Card
                title={
                  (data.type === "privacy" && "개인정보처리방침") ||
                  (data.type === "service" && "서비스이용약관")
                }
                content={
                  <form onSubmit={onSubmit}>                   
                    
                    <Row>
                      <Col md={12}>
                      <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "수정일",
                          type: "text",
                          bsClass: "form-control",
                          name: "updatedAt",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.updatedAt
                            ? Moment(input.updatedAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                        <FormGroup controlId="formControlsTextarea">
                          <ControlLabel>내용</ControlLabel>
                          <ReactSummernote
                            options={{
                              lang: "ko-KR",
                              height: 250,
                              dialogsInBody: true,
                              toolbar: [
                                ["style", ["style"]],
                                ["font", ["bold", "underline", "clear"]],
                                ["fontname", ["fontname"]],
                                ["para", ["ul", "ol", "paragraph"]],
                                ["table", ["table"]],
                                ["insert", ["link", "picture", "video"]],
                                ["view", ["fullscreen", "codeview"]],
                              ],
                            }}
                            value={editorText}
                            onChange={onChange}
                            onImageUpload={onImageUpload}
                            onInit={onInit}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button
                      bsStyle="info"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={onSubmit}
                    >
                      수정
                    </Button>
                    <Button
                        bsStyle="success"
                        style={{ marginLeft: "1%" }}
                        pullRight
                        fill
                        onClick={(e) => history.goBack()}
                      >
                        목록
                      </Button>
                    <div className="clearfix" />
                  </form>
                }
              />             
            </Col>
          </Row>
        )}
      </Grid>

    </div>
  );
}

export default TermDetail;
