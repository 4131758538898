import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  addFormData,
  editFormData,
  addJsonData,
  addFileUpload,
  fetchData,
  deleteData,
} from "modules/api.js";
import Moment from "moment";
import "draft-js-image-plugin/lib/plugin.css";
import "../../global";
import "bootstrap/js/modal";
import "bootstrap/js/dropdown";
import "bootstrap/js/tooltip";
import "bootstrap/dist/css/bootstrap.css";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-ko-KR";

function ColumnDetail(props) {
  const detailType = {
    id: "",
    title: "",
  };
  const [input, setInput] = useState(detailType);
  const [data, setData] = useState();

  const [editorText, setEditorText] = useState("");

  const history = useHistory();

  const reload = (param) => {
    //데이터 로딩
    if (props.match.params.value !== "add") {
      (async function anyNameFunction() {
        const result = await fetchData(`user/column/${param}`);

        console.log(result.data);
        setData(result.data);
        setInput(result.data);
        setEditorText(result.data.contents);
      })();
    }
  };

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const changeProfileImage = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        file: file,
        image: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
    }

    reload(Number(props.match.params.value));
  }, []);

  const onSubmit = () => {
    console.log("칼럼수정시도");
    console.log(input);
    if (!input.title || input.title == "") {
      alert("제목을 입력해주세요");
      return false;
    } else if (input.title.length > 100) {
      alert("제목은 100자 이하로 작성해주세요");
      return false;
    }
    if (!editorText) {
      alert("내용을 입력해주세요");
      return false;
    }

    const bodyFormData = new FormData();
    // bodyFormData.set("id", input.id);
    bodyFormData.set("title", input.title);
    bodyFormData.set("contents", editorText);
    bodyFormData.set("tags", input.tags);
    if (input.file != undefined && input.file != null) {
      bodyFormData.set("files", input.file);
    }

    editFormData(`admin/column/${input.id}`, bodyFormData).then(function (
      response
    ) {
      console.log(response);
      if (response.result) {
        alert("칼럼 수정이 완료되었습니다.");
        history.push(`/admin/columnList/1`);
      } else {
        alert(response.msg);
      }
    });
  };

  const onAdd = () => {
    console.log(input);
    if (!input.title || input.title == "") {
      alert("제목을 입력해주세요");
      return false;
    } else if (input.title.length > 100) {
      alert("제목은 100자 이하로 작성해주세요");
      return false;
    }
    if (!editorText) {
      alert("내용을 입력해주세요");
      return false;
    }

    const bodyFormData = new FormData();
    bodyFormData.set("title", input.title);
    bodyFormData.set("contents", editorText);
    bodyFormData.set("tags", input.tags);
    if (input.file) {
      bodyFormData.set("files", input.file);
    }

    addFormData(`admin/column`, bodyFormData).then(function (response) {
      console.log(response);
      if (response.result) {
        alert("칼럼 등록이 완료되었습니다.");
        history.push(`/admin/columnList/1`);
      } else {
        alert(response);
      }
    });
  };

  const onRemove = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      deleteData(`admin/column`, input.id).then(function (response) {
        if (response.result) {
          alert("삭제성공");
          history.push(`/admin/columnList/1`);
        } else {
          alert("삭제실패");
        }
      });
    }
  };

  /**
   *
   * 서머노트 관련 함수
   */
  const onChange = (content) => {
    setEditorText(content);
  };

  const onImageUpload = (images, insertImage) => {
    console.log("onImageUpload", images);
    const bodyFormData = new FormData();
    for (let i = 0; i < images.length; i++) {
      bodyFormData.set("files", images[i]);
      addFileUpload(`admin/image-insert`, bodyFormData).then(function (
        response
      ) {
        console.log(response.data.filePath);
        insertImage(response.data.filePath);
      });
    }
  };

  const onInit = (note) => {
    note.reset();
    const regex = /(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/i;
    if (data) {
      console.log("tset", data.content.match(regex));
      if (data && data.content.match(regex) !== null) {
        note.replace(data && data.content);
      } else {
        note.insertText(data && data.content);
      }
    }
  };

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={9}>
            {input && (
              <Card
                title={
                  (props.match.params.value !== "add" && "칼럼 정보") ||
                  (props.match.params.value === "add" && "칼럼 등록")
                }
                content={
                  <form onSubmit={onSubmit}>
                    {props.match.params.value !== "add" && (
                      <FormInputs
                        ncols={["col-md-2", "col-md-5", "col-md-5"]}
                        properties={[
                          {
                            label: "번호",
                            type: "text",
                            bsClass: "form-control",
                            name: "id",
                            readOnly: true,
                            value: input.id || "",
                            style: { cursor: "initial" },
                          },
                          {
                            label: "등록일",
                            type: "text",
                            bsClass: "form-control",
                            name: "createdAt",
                            readOnly: true,
                            value:
                              Moment(input.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              ) || "",
                            style: { cursor: "initial" },
                          },
                          {
                            label: "수정일",
                            type: "text",
                            bsClass: "form-control",
                            name: "updatedAt",
                            readOnly: true,
                            value:
                              Moment(input.updatedAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              ) || "",
                            style: { cursor: "initial" },
                          },
                        ]}
                      />
                    )}

                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "제목",
                          type: "text",
                          bsClass: "form-control",
                          name: "title",
                          // readOnly: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.title || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <Row>
                      <Col md={12}>
                        <FormGroup controlId="formControlsTextarea">
                          <ControlLabel>내용</ControlLabel>
                          <ReactSummernote
                            options={{
                              lang: "ko-KR",
                              height: 350,
                              dialogsInBody: true,
                              toolbar: [
                                ["style", ["style"]],
                                ["font", ["bold", "underline", "clear"]],
                                ["fontname", ["fontname"]],
                                ["para", ["ul", "ol", "paragraph"]],
                                ["table", ["table"]],
                                ["insert", ["link", "picture", "video"]],
                                ["view", ["fullscreen", "codeview"]],
                              ],
                            }}
                            value={editorText}
                            onChange={onChange}
                            onImageUpload={onImageUpload}
                            onInit={onInit}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "태그",
                          type: "text",
                          bsClass: "form-control",
                          name: "tags",
                          // readOnly: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.tags || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    {/* <FormGroup controlId="formControlsTextarea">
                      <ControlLabel>내용</ControlLabel>
                      <FormControl
                        rows="4"
                        componentClass="textarea"
                        bsClass="form-control"
                        onChange={(e) => handleInputChange(e)}
                        name="contents"
                        value={input.contents || ""}
                        style={{ resize: "none", cursor: "initial" }}
                      />
                    </FormGroup> */}

                    {props.match.params.value === "add" && (
                      <Button
                        bsStyle="info"
                        style={{ marginLeft: "1%" }}
                        pullRight
                        fill
                        onClick={(e) => onAdd(e)}
                      >
                        등록
                      </Button>
                    )}
                    {props.match.params.value !== "add" && (
                      <>
                        <Button
                          bsStyle="info"
                          style={{ marginLeft: "1%" }}
                          pullRight
                          fill
                          onClick={(e) => onSubmit(e)}
                        >
                          수정
                        </Button>
                        <Button
                          bsStyle="warning"
                          style={{ marginLeft: "1%" }}
                          pullRight
                          fill
                          onClick={(e) => onRemove(e)}
                        >
                          삭제
                        </Button>
                        <Button
                          bsStyle="success"
                          style={{ marginLeft: "1%" }}
                          pullRight
                          fill
                          onClick={(e) => history.goBack()}
                        >
                          목록
                        </Button>
                      </>
                    )}
                    <div className="clearfix" />
                  </form>
                }
              />
            )}
          </Col>
          <Col md={3}>
            {input && (
              <Card
                title="대표 이미지"
                content={
                  <>
                   <label
                        className="fileInputStyle"
                        htmlFor="fileInput3"
                        style={{
                          color: "rgba(0, 0, 0, 0.87)",
                          marginBottom: "15px",
                          fontSize: "14px",
                        }}
                      >
                        업로드
                      </label>
                      <input
                        id="fileInput3"
                        type="file"
                        name="imageFile"
                        style={{ marginBottom: "10px", display: "none" }}
                        onChange={changeProfileImage}
                        accept=".jpg, .jpeg, .png, .bmp"
                      />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        style={{
                          maxWidth: "90%",
                          maxHeight: "350px",
                          borderRadius: "5px",
                          verticalAlign: "middle",
                        }}
                        src={input.image || ""}
                      />
                    </div>
                  </>
                }
              />
            )}
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default ColumnDetail;
