import React, { Component } from "react";
import { NavLink, Link, BrowserRouter as Router } from "react-router-dom";

import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";

import logo from "assets/img/477@3x.png";
import sidebarMenu from "./sidebarMenu";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  render() {
    const sidebarBackground = {
      backgroundImage: "url(" + this.props.image + ")",
    };
    return (
      <div
        id="sidebar"
        className="sidebar"
        style={{
          background:
            "linear-gradient(to bottom, #a93636 0%, #111 100%) !important",
        }}
      >
        <div className="logo">
          <a
            href="/admin/userList/1"
            className="simple-text logo-normal"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textDecoration: "none",
              // height: "40px",
            }}
          >
            <div>PETOVE</div>
            {/* <img
              src={logo}
              style={{
                // width: "90px",
                // height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "selfEnd",
              }}
            /> */}
          </a>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            {this.state.width <= 991 ? (
              <AdminNavbarLinks onLogout={this.props.onLogout} />
            ) : null}
            {/* {this.props.routes.map((prop, key) => { */}
            {/* <Router forceRefresh> */}
              {sidebarMenu.map((prop, key) => {
                if (!prop.redirect)
                  //window.location.replace("/")
                  return (
                    <li
                      className={this.activeRoute(prop.layout + prop.path)}
                      key={key}
                    >
                      <Link
                        to={
                          prop.path === "/companyInfo" 
                          || prop.path === "/colorList" 
                          // || prop.path === "/terms"
                            ? prop.layout + prop.path
                            : prop.layout + prop.path + "/1"
                        }
                        className="nav-link"
                        activeclassname="active"
                      >
                        <i className={prop.icon} />
                        <p style={{ fontSize: "15px" }}>{prop.name}</p>
                      </Link>
                    </li>
                  );
                return null;
              })}
            {/* </Router> */}
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;
