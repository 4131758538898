import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { useHistory } from "react-router-dom";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  fetchData,
  editFormData,
  getData,
  addFormData,
  addData,
  deleteData,
} from "modules/api.js";
import { useStyles } from "./style.jsx";
import Moment from "moment";

function PetDetail(props) {
  const [input, setInput] = useState();
  const history = useHistory();
  const [data, setData] = useState();

  const reload = () => {
    (async function anyNameFunction() {
      const result = await fetchData(`admin/pet/${props.match.params.value}`);

      setInput(result.data);
      setData(result.data);
    })();
  };

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const changeProfileImage = (e) => {
    let reader = new FileReader();
    let imageFile = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        files: imageFile,
        profileImg: reader.result,
      });
    };
    reader.readAsDataURL(imageFile);
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
    }
    reload();
  }, []);

  const onSubmit = () => {
    if (!input.nickname || input.nickname === "") {
      alert("닉네임을 입력해주세요");
      return false;
    }

    const bodyFormData = new FormData();
    bodyFormData.set("id", input.id);
    bodyFormData.set("nickname", input.nickname);

    if (input.profileImg != undefined && input.profileImg != null) {
      bodyFormData.set("profileImg", input.profileImg);
      bodyFormData.set("files", input.files);
    }

    editFormData(`admin/pet/${input.id}`, bodyFormData).then(function (
      response
    ) {
      console.log(response);
      if (response.result) {
        alert("펫 정보 수정이 완료되었습니다.");
        history.push(`/admin/petList/1`);
      } else {
        alert(response);
      }
    });
  };

  const onRemove = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      deleteData(`admin/pet`, input.id).then(function (response) {
        if (response.result) {
          alert("삭제성공");
          history.push(`/admin/petList/1`);
        } else {
          alert("삭제실패");
          return false;
        }
      });
    }
  };

  return (
    <div className="content">
      <Grid fluid>
        {data && (
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col md={7}>
              <Card
                title="펫 상세정보"
                content={
                  <form onSubmit={onSubmit}>
                    <FormInputs
                      ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                      properties={[
                        {
                          label: "번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "id",
                          disabled: true,
                          value: input.id || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "보호자",
                          type: "text",
                          bsClass: "form-control",
                          name: "userName",
                          disabled: true,
                          value: input.user ? input.user.name : "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "이름",
                          type: "text",
                          bsClass: "form-control",
                          name: "name",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.name || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "등록일",
                          type: "text",
                          bsClass: "form-control",
                          name: "createdAt",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.createdAt
                            ? Moment(input.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />

                    <FormInputs
                      ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                      properties={[
                        {
                          label: "종",
                          type: "text",
                          bsClass: "form-control",
                          name: "speciesName",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.species ? input.species.nameKr : "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "품종",
                          type: "text",
                          bsClass: "form-control",
                          name: "breedName",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.breed ? input.breed.nameKr : "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "성별",
                          type: "text",
                          bsClass: "form-control",
                          name: "gender",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.gender || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "생일",
                          type: "text",
                          bsClass: "form-control",
                          name: "birth",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.birth || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                      properties={[
                        {
                          label: "동물등록번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "regNum",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.regNum || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "키",
                          type: "text",
                          bsClass: "form-control",
                          name: "height",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.height || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "몸무게",
                          type: "text",
                          bsClass: "form-control",
                          name: "weight",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.weight || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "특성",
                          type: "text",
                          bsClass: "form-control",
                          name: "feature",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.feature || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    {/* <Button
                      bsStyle="info"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={onSubmit}
                    >
                      수정
                    </Button> */}
                    <Button
                      bsStyle="warning"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={onRemove}
                    >
                      삭제
                    </Button>
                    <Button
                      bsStyle="success"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={(e) => history.goBack()}
                    >
                      목록
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
            <Col md={3}>
              {input && (
                <Card
                  title="프로필 이미지"
                  content={
                    <>
                      {/* <label
                        className="fileInputStyle"
                        htmlFor="fileInput"
                        style={{
                          color: "rgba(0, 0, 0, 0.87)",
                          marginBottom: "15px",
                          fontSize: "14px",
                        }}
                      >
                        업로드
                      </label> */}
                      <input
                        id="fileInput"
                        type="file"
                        name="files"
                        style={{ marginBottom: "10px", display: "none" }}
                        onChange={changeProfileImage}
                        accept=".jpg, .jpeg, .png, .bmp"
                      />
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          style={{
                            maxWidth: "90%",
                            maxHeight: "350px",
                            borderRadius: "5px",
                            verticalAlign: "middle",
                          }}
                          src={input.profileImg || ""}
                        />
                      </div>
                    </>
                  }
                />
              )}
            </Col>
          </Row>
        )}
      </Grid>
    </div>
  );
}

export default PetDetail;
