import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { useHistory } from "react-router-dom";
// import { UserCard } from "views/users/node_modules/components/UserCard/UserCard.jsx.js";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  fetchData,
  editFormData,
  addFileUpload,
  deleteData,
} from "modules/api.js";
import { useStyles } from "./style.jsx";
import Moment from "moment";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-ko-KR";

function ReportDetail(props) {
  const [input, setInput] = useState();
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState();
  const [editorText, setEditorText] = useState("");
  const [speciesList, setSpeciesList] = useState([]);
  const [breedList, setBreedList] = useState([]);
  const reload = () => {
    (async function anyNameFunction() {
      const result = await fetchData(
        `admin/report/${props.match.params.value}`
      );
      console.log(result);
      setInput(result.data);
      setData(result.data);
    })();
  };

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
      // return false;
    }
    reload();
  }, []);

  const onSubmit = () => {
    const bodyFormData = new FormData();
    bodyFormData.set("finish", input.finish);

    editFormData(`admin/report/${input.id}`, bodyFormData).then(function (
      response
    ) {
      console.log(response);
      if (response.result) {
        alert("신고 수정이 완료되었습니다.");
        history.push(`/admin/reportList/1`);
      } else {
        alert(response);
      }
    });
  };

  return (
    <div className="content">
      <Grid fluid>
        {data && (
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col md={7}>
              <Card
                title="신고 상세정보"
                content={
                  <form onSubmit={onSubmit}>
                    <FormInputs
                      ncols={["col-md-3", "col-md-5", "col-md-4"]}
                      properties={[
                        {
                          label: "번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "id",
                          disabled: true,
                          value: input.id || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "등록일",
                          type: "text",
                          bsClass: "form-control",
                          name: "createdAt",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.createdAt
                            ? Moment(input.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "상태",
                          type: "select",
                          bsClass: "form-control",
                          name: "finish",
                          className: "infoSelect",
                          // disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.finish || "",
                          style: { cursor: "initial" },
                          option: (
                            <>
                              <option value="false" key="false">
                                대기
                              </option>
                              <option value="true" key="true">
                                완료
                              </option>
                            </>
                          ),
                        },
                      ]}
                    />

                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        {
                          label: "분류",
                          type: "text",
                          bsClass: "form-control",
                          name: "type",
                          onChange: (e) => handleInputChange(e),
                          disabled: true,
                          value:
                            input.type && input.type === "board"
                              ? "게시글"
                              : input.type === "comment"
                              ? "댓글"
                              : "" || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "원글번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "contentsId",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.contentsId || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "작성자",
                          type: "text",
                          bsClass: "form-control",
                          name: "userId",
                          onChange: (e) => handleInputChange(e),
                          disabled: true,
                          value: input.user ? input.user.name : "" || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />

                    {/* <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "사유",
                          type: "text",
                          bsClass: "form-control",
                          name: "reason",
                          readOnly: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.reason || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    /> */}
                    <FormGroup controlId="formControlsTextarea">
                      <ControlLabel>사유</ControlLabel>
                      <FormControl
                        rows="4"
                        componentClass="textarea"
                        bsClass="form-control"
                        onChange={(e) => handleInputChange(e)}
                        name="reason"
                        readOnly={true}
                        value={
                          input.reason &&
                          input.reason != "undefined" &&
                          input.reason != "null"
                            ? input.reason
                            : ""
                        }
                        style={{ resize: "none", cursor: "initial" }}
                      />
                    </FormGroup>

                    <Button
                      bsStyle="info"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={onSubmit}
                    >
                      수정
                    </Button>
                    <Button
                      bsStyle="success"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={(e) => history.goBack()}
                    >
                      목록
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        )}
      </Grid>
    </div>
  );
}

export default ReportDetail;
