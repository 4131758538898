import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import logo from "assets/img/477@3x.png";
import { getLogin } from "modules/api.js";

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ fontSize: "15px" }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Petove
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide(props) {
  const history = useHistory();
  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";

    if (auth != "") {
      history.push("/admin/userList/1");
    }
  }, []);
  const classes = useStyles();

  const [input, setInput] = useState({
    loginId: "",
    pwd: "",
  });

  useEffect(() => {}, []);

  const onChange = (event) => {
    if (window.event.keyCode === 13) {
      props.onSubmitLogin(input);
    }
    setInput({
      ...input,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  return (
    <Grid
      container
      component="main"
      className={classes.root}
      style={{ width: "30vw", alignItems: "center", margin: "auto" }}
    >
      <CssBaseline />
      <div>
        <div style={{ width: "100%", marginBottom: "30px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={logo}
              style={{
                width: "100px",
                marginBottom: "20px",
                // height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "selfEnd",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#000000",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              style={{ fontSize: "22px" }}
            >
              Administrator
            </Typography>
          </div>
        </div>
        <Grid
          // className="signInCard"
          item
          xs={12}
          sm={12}
          md={12}
          component={Paper}
          elevation={6}
          square
          style={{ borderRadius: "5px", padding: "1px" }}
        >
          <div
            style={{ margin: "30px 30px" }}
            //  className={classes.paper}
          >
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                style={{ fontSize: "20px" }}
                className="fontStyle md20"
                placeholder="login Id(name)"
                name="name"
                autoComplete="name"
                value={input.name || ""}
                onChange={(e) => onChange(e)}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                className="fontStyle"
                style={{ fontSize: "20px" }}
                name="phone"
                placeholder="Password"
                type="password"
                id="phone"
                onChange={(e) => onChange(e)}
                onKeyUp={(e) => onChange(e)}
                value={input.phone || ""}
                autoComplete="current-password"
              />
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => props.onSubmitLogin(input)}
                style={{
                  backgroundColor: "#3a454c7a",
                  fontSize: "16px",
                  height: "50px",
                  marginTop: "30px",
                }}
              >
                로그인
              </Button>
            </form>
          </div>
        </Grid>
      </div>
    </Grid>
  );
}
