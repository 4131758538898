import React, { useState, useEffect } from "react";
import { fetchData } from "modules/api.js";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import Pagination from "@material-ui/lab/Pagination";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import {
  style,
  buttonDivStyle,
  buttonStyle1,
  BootstrapInput,
  useStyles,
} from "./style.jsx";
import { el } from "date-fns/locale";

function PetList(props) {
  const petThArray = [
    "번호",
    "종",
    "품종",
    "이름",
    "보호자",
    "생일",
    "성별",
    "몸무게",
    "등록일",
  ];
  const classes = useStyles();
  const [dataList, setDataList] = useState();
  const [total, setTotal] = useState(1);
  const history = useHistory();
  const [searchData, setSearchData] = useState({
    speciesId: "",
    breedId: "",
  });
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState();
  const [speciesList, setSpeciesList] = useState();
  const [breedList, setBreedList] = useState();

  const reload = (value) => {
    if (value === undefined || value === null) {
      value = page;
    }

    (async function anyNameFunction() {
      const result = await fetchData(
        `admin/petList?page=${value}&speciesId=${searchData.speciesId}&breedId=${searchData.breedId}`
      );
      console.log(result);

      setTotalItem(result.data.count);
      setTotal(Math.ceil(result.data.count / 10));
      setDataList(result.data.rows);
    })();
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
    }
    (async function anyNameFunction() {
      const result = await fetchData(`admin/speciesBreedList`);
      console.log(result);
      setSpeciesList(result.data);
    })();
    // console.log("재로딩");
    setPage(Number(props.match.params.value));
    reload(Number(props.match.params.value));
  }, []);

  const pageChange = (event, value) => {
    setPage(value);
    reload(value);
    history.push(`/admin/petList/${value}`);
  };

  const searchChange = (event) => {
    if (window.event.keyCode === 13) {
      reload(1);
    }

    if (event.target.name === "speciesId") {
      setSearchData({
        breedId: "",
        speciesId: event.target.value,
      });
      let breedList = speciesList.filter(
        (item) => item.id === Number(event.target.value)
      );
      console.log(breedList);
      if (breedList[0] && breedList[0].breeds) {
        setBreedList(breedList[0].breeds);
      } else {
        setBreedList([]);
      }
    } else {
      setSearchData({
        ...searchData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const onSearch = () => {
    setPage(1);
    reload(1);
  };

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="펫 관리"
              ctTableFullWidth
              ctTableResponsive
              content={
                <div>
                  <div style={buttonDivStyle}>
                    <FormControl className={classes.margin}>
                      <NativeSelect
                        id="demo-customized-select-native"
                        // value={searchData.type}
                        onChange={(e) => searchChange(e)}
                        input={<BootstrapInput />}
                        name="speciesId"
                        style={{ font: "initial" }}
                      >
                        <>
                          <option value="">전체</option>
                          {speciesList &&
                            speciesList.map((item, index) => {
                              return (
                                <option value={item.id} key={`species${index}`}>
                                  {item.nameKr}
                                </option>
                              );
                            })}
                        </>
                      </NativeSelect>
                    </FormControl>
                    <FormControl className={classes.margin}>
                      <NativeSelect
                        id="demo-customized-select-native"
                        // value={searchData.type}
                        onChange={(e) => searchChange(e)}
                        input={<BootstrapInput />}
                        name="breedId"
                        style={{ font: "initial" }}
                      >
                        <>
                          <option value="">전체</option>
                          {breedList &&
                            breedList.map((item, index) => {
                              return (
                                <option value={item.id} key={`breed${index}`}>
                                  {item.nameKr}
                                </option>
                              );
                            })}
                        </>
                      </NativeSelect>
                    </FormControl>
                    <Button
                      variant="outlined"
                      style={buttonStyle1}
                      onClick={(e) => onSearch(e)}
                    >
                      검색
                    </Button>
                  </div>
                  <Table striped hover>
                    <thead>
                      <tr>
                        {petThArray.map((prop, key) => {
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {dataList && dataList.length > 0 ? (
                        dataList.map((data, index) => {
                          // data.no = totalItem - (page - 1) * 20 - index;
                          // console.log(dataList.length);
                          return (
                            <tr
                              key={index}
                              onClick={(e) =>
                                history.push(`/admin/pet/${data.id}`)
                              }
                            >
                              <td>{data.id}</td>
                              <td>{data.species ? data.species.nameKr : ""}</td>
                              <td>{data.breed ? data.breed.nameKr : ""}</td>
                              <td>{data.name}</td>
                              <td>{data.name}</td>
                              <td>{data.birth}</td>
                              <td>{data.gender}</td>
                              <td>{data.height}</td>
                              <td>
                                {(data.createdAt &&
                                  Moment(data.createdAt).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )) ||
                                  ""}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="9" style={{ textAlign: "center" }}>
                            검색결과가 없습니다
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              }
            />
            <Pagination
              count={total}
              page={page}
              size="large"
              onChange={pageChange}
              variant="outlined"
              shape="rounded"
              style={style}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default PetList;
