const sidebarMenu = [
  {
    path: "/userList",
    name: "회원 관리",
    icon: "pe-7s-user-female",
    layout: "/admin",
  },
  {
    path: "/petList",
    name: "펫 관리",
    icon: "pe-7s-like",
    layout: "/admin",
  },
  {
    path: "/calendarList",
    name: "캘린더 관리",
    icon: "pe-7s-date",
    layout: "/admin",
  },
  {
    path: "/speciesList",
    name: "종 관리",
    icon: "pe-7s-ribbon",
    layout: "/admin",
  },
  {
    path: "/breedList",
    name: "품종 관리",
    icon: "pe-7s-network",
    layout: "/admin",
  },
  {
    path: "/colorList",
    name: "색상 관리",
    icon: "pe-7s-note",
    layout: "/admin",
  },
  {
    path: "/breedInfoList",
    name: "동물사전 관리",
    icon: "pe-7s-notebook",
    layout: "/admin",
  },
  {
    path: "/brandList",
    name: "브랜드 관리",
    icon: "pe-7s-ticket",
    layout: "/admin",
  },
  {
    path: "/itemList",
    name: "상품 관리",
    icon: "pe-7s-gift",
    layout: "/admin",
  },
  {
    path: "/recommList",
    name: "추천글 관리",
    icon: "pe-7s-like2",
    layout: "/admin",
  },
  {
    path: "/pushList",
    name: "PUSH 관리",
    icon: "pe-7s-light",
    layout: "/admin",
  },
  {
    path: "/columnList",
    name: "칼럼 관리",
    icon: "pe-7s-pen",
    layout: "/admin",
  },
  {
    path: "/noticeList",
    name: "공지사항 관리",
    icon: "pe-7s-bell",
    layout: "/admin",
  },
  {
    path: "/faqList",
    name: "FAQ 관리",
    icon: "pe-7s-help1",
    layout: "/admin",
  },
  {
    path: "/companyInfo",
    name: "회사정보",
    icon: "pe-7s-info",
    layout: "/admin",
  },
  {
    path: "/termList",
    name: "약관 관리",
    icon: "pe-7s-news-paper",
    layout: "/admin",
  },
  {
    path: "/adList",
    name: "광고 관리",
    icon: "pe-7s-diamond",
    layout: "/admin",
  },

  {
    path: "/boardList",
    name: "게시글 관리",
    icon: "pe-7s-chat",
    layout: "/admin",
  },
  {
    path: "/commentList",
    name: "댓글 관리",
    icon: "pe-7s-comment",
    layout: "/admin",
  },
  {
    path: "/reportList",
    name: "신고 관리",
    icon: "pe-7s-attention",
    layout: "/admin",
  },
  {
    path: "/infoEditList",
    name: "정보수정제안 관리",
    icon: "pe-7s-magic-wand",
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Example - Icons",
  //   icon: "pe-7s-science",
  //   layout: "/admin",
  // },
];

export default sidebarMenu;
