import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { useHistory } from "react-router-dom";
// import { UserCard } from "views/users/node_modules/components/UserCard/UserCard.jsx.js";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  fetchData,
  editFormData,
  addFileUpload,
  deleteData,
} from "modules/api.js";
import { useStyles } from "./style.jsx";
import Moment from "moment";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-ko-KR";

function BoardDetail(props) {
  const [input, setInput] = useState();
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState();
  const [editorText, setEditorText] = useState("");
  const [speciesList, setSpeciesList] = useState([]);
  const [breedList, setBreedList] = useState([]);
  const reload = () => {
    (async function anyNameFunction() {
      const result = await fetchData(`admin/board/${props.match.params.value}`);
      console.log(result);
      setSpeciesList(result.data.species);
      if (
        result.data.board.group1 !== null &&
        result.data.board.group1 !== ""
      ) {
        let thisBreeds = result.data.species.filter(
          (item) => item.id === Number(result.data.board.group1)
        );
        if (thisBreeds[0] && thisBreeds[0].breeds)
          setBreedList(thisBreeds[0].breeds);
      } else {
        setBreedList(result.data.species[0].breeds);
      }
      setInput(result.data.board);
      setData(result.data.board);
      setEditorText(result.data.board.contents);
    })();
  };

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handleSpeciesChange = (e) => {
    console.log(speciesList);
    console.log(e.target.value);
    let newBreedList = speciesList.filter(
      (item) => item.id === Number(e.target.value)
    );
    console.log(newBreedList);
    setBreedList(newBreedList[0].breeds);
    setInput({
      ...input,
      group1: e.currentTarget.value,
      group2: "",
    });
  };

  const changeProfileImage = (e) => {
    console.log("프로필파일");
    let reader = new FileReader();
    let imageFile = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        profileImageFile: imageFile,
        profileImg: reader.result,
      });
    };
    reader.readAsDataURL(imageFile);
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
      // return false;
    }
    reload();
  }, []);

  const onSubmit = () => {
    if (!input.title || input.title == "") {
      alert("제목을 입력해주세요");
      return false;
    }
    if (!editorText) {
      alert("내용을 입력해주세요");
      return false;
    }
    if (!input.group2 || input.group2 == "") {
      alert("소분류를 입력해주세요");
      return false;
    }
    const bodyFormData = new FormData();
    bodyFormData.set("title", input.title);
    bodyFormData.set("contents", editorText);
    bodyFormData.set("tags", input.tags);
    bodyFormData.set("type", input.type);
    bodyFormData.set("group1", input.group1 === "" ? null : input.group1);
    bodyFormData.set("group2", input.group2 === "" ? null : input.group2);
    if (input.profileImageFile != undefined && input.profileImageFile != null) {
      bodyFormData.set("files", input.profileImageFile);
    }

    editFormData(`admin/board/${input.id}`, bodyFormData).then(function (
      response
    ) {
      console.log(response);
      if (response.result) {
        alert("게시글 수정이 완료되었습니다.");
        history.push(`/admin/boardList/1`);
      } else {
        alert(response);
      }
    });
  };

  const onRemove = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      deleteData(`admin/board`, input.id).then(function (response) {
        if (response.result) {
          alert("삭제성공");
          history.push(`/admin/boardList/1`);
        } else {
          alert("삭제실패");
        }
      });
    }
  };

  /**
   *
   * 서머노트 관련 함수
   */
  const onChange = (content) => {
    setEditorText(content);
  };

  const onImageUpload = (images, insertImage) => {
    console.log("onImageUpload", images);
    const bodyFormData = new FormData();
    for (let i = 0; i < images.length; i++) {
      bodyFormData.set("files", images[i]);
      addFileUpload(`admin/image-insert`, bodyFormData).then(function (
        response
      ) {
        console.log(response);
        insertImage(response.data.filePath);
      });
    }
  };

  const onInit = (note) => {
    note.reset();
    const regex = /(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/i;
    if (data && data.contents) {
      console.log("tset", data.contents.match(regex));
      if (data.contents.match(regex) !== null) {
        note.replace(data && data.contents);
      } else {
        note.insertText(data && data.contents);
      }
    }
  };

  return (
    <div className="content">
      <Grid fluid>
        {data && (
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col md={7}>
              <Card
                title="게시글 상세정보"
                content={
                  <form onSubmit={onSubmit}>
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        {
                          label: "번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "id",
                          disabled: true,
                          value: input.id || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "가입일",
                          type: "text",
                          bsClass: "form-control",
                          name: "createdAt",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.createdAt
                            ? Moment(input.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "수정일",
                          type: "text",
                          bsClass: "form-control",
                          name: "updatedAt",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.updatedAt
                            ? Moment(input.updatedAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        {
                          label: "구분",
                          type: "select",
                          bsClass: "form-control",
                          name: "type",
                          className: "infoSelect",
                          // disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.type || "",
                          style: { cursor: "initial" },
                          option: (
                            <>
                              <option value="tip" key="tip">
                                꿀팁
                              </option>
                              <option value="question" key="question">
                                질문
                              </option>
                              <option value="review" key="review">
                                후기
                              </option>
                            </>
                          ),
                        },
                        {
                          label: "대분류",
                          type: "select",
                          bsClass: "form-control",
                          name: "group1",
                          className: "infoSelect",
                          onChange: (e) => handleSpeciesChange(e),
                          // disabled: true,
                          value: input.group1,
                          style: { cursor: "initial" },
                          option: (
                            <>
                              {speciesList &&
                                speciesList.map((item, index) => {
                                  return (
                                    <option
                                      value={item.id}
                                      key={`species${index}`}
                                    >
                                      {item.nameKr}
                                    </option>
                                  );
                                })}
                            </>
                          ),
                        },
                        {
                          label: "소분류",
                          type: "select",
                          bsClass: "form-control",
                          name: "group2",
                          className: "infoSelect",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.group2 || "",
                          style: { cursor: "initial" },
                          option: (
                            <>
                              <option value="" key="none">
                                선택
                              </option>
                              {breedList &&
                                breedList.map((item, index) => {
                                  return (
                                    <option
                                      value={item.id}
                                      key={`breed${index}`}
                                    >
                                      {item.nameKr}
                                    </option>
                                  );
                                })}
                            </>
                          ),
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-8", "col-md-2", "col-md-2"]}
                      properties={[
                        {
                          label: "제목",
                          type: "text",
                          bsClass: "form-control",
                          name: "title",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.title || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "글쓴이",
                          type: "text",
                          bsClass: "form-control",
                          name: "type",
                          onChange: (e) => handleInputChange(e),
                          disabled: true,
                          value: input.user ? input.user.name : "" || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "조회수",
                          type: "text",
                          bsClass: "form-control",
                          name: "view",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.view || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />

                    <Row>
                      <Col md={12}>
                        <FormGroup controlId="formControlsTextarea">
                          <ControlLabel>내용</ControlLabel>
                          <ReactSummernote
                            options={{
                              lang: "ko-KR",
                              height: 350,
                              dialogsInBody: true,
                              toolbar: [
                                ["style", ["style"]],
                                ["font", ["bold", "underline", "clear"]],
                                ["fontname", ["fontname"]],
                                ["para", ["ul", "ol", "paragraph"]],
                                ["table", ["table"]],
                                ["insert", ["link", "picture", "video"]],
                                ["view", ["fullscreen", "codeview"]],
                              ],
                            }}
                            value={editorText}
                            onChange={onChange}
                            onImageUpload={onImageUpload}
                            onInit={onInit}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "태그",
                          type: "text",
                          bsClass: "form-control",
                          name: "tags",
                          // readOnly: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.tags || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />

                    <Button
                      bsStyle="info"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={onSubmit}
                    >
                      수정
                    </Button>
                    <Button
                      bsStyle="warning"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={(e) => onRemove(e)}
                    >
                      삭제
                    </Button>
                    <Button
                      bsStyle="success"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={(e) => history.goBack()}
                    >
                      목록
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
            <Col md={3}>
              {input && (
                <>
                  <Card
                    title="대표 이미지"
                    content={
                      <>
                        <label
                          className="fileInputStyle"
                          htmlFor="fileInput3"
                          style={{
                            color: "rgba(0, 0, 0, 0.87)",
                            marginBottom: "15px",
                            fontSize: "14px",
                          }}
                        >
                          업로드
                        </label>
                        <input
                          id="fileInput3"
                          type="file"
                          name="profileImageFile"
                          style={{ marginBottom: "10px", display: "none" }}
                          onChange={changeProfileImage}
                          accept=".jpg, .jpeg, .png, .bmp"
                        />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            style={{
                              maxWidth: "90%",
                              maxHeight: "350px",
                              borderRadius: "5px",
                              verticalAlign: "middle",
                            }}
                            src={input.profileImg || ""}
                          />
                        </div>
                      </>
                    }
                  />
                </>
              )}
            </Col>
          </Row>
        )}
      </Grid>
    </div>
  );
}

export default BoardDetail;
