import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  addFormData,
  editFormData,
  addJsonData,
  addFileUpload,
  fetchData,
  deleteData,
} from "modules/api.js";
import Moment from "moment";
import "draft-js-image-plugin/lib/plugin.css";
import "../../global";
import "bootstrap/js/modal";
import "bootstrap/js/dropdown";
import "bootstrap/js/tooltip";
import "bootstrap/dist/css/bootstrap.css";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-ko-KR";
import SpeciesList from "views/species/SpeciesList";

function BreedInfoDetail(props) {
  const detailType = {
    id: "",
    title: "",
    contents: "",
    type: "overview",
  };
  const [input, setInput] = useState(detailType);
  const [data, setData] = useState();
  const [speciesList, setSpeciesList] = useState();
  const [breedList, setBreedList] = useState();

  const [editorText, setEditorText] = useState("");

  const history = useHistory();

  const reload = (param) => {
    //데이터 로딩

    (async function anyNameFunction() {
      const species = await fetchData(`admin/speciesBreedList`);
      setSpeciesList(species.data);
      setBreedList(species.data[0].breeds);
      if (props.match.params.value !== "add") {
        (async function anyNameFunction() {
          let result = await fetchData(`admin/breedInfo/${param}`);
          if (result.data.breed) {
            result.data.speciesId = result.data.breed.speciesId;
            let newList = species.data.filter(
              (item) => item.id == result.data.breed.speciesId
            );
            setBreedList(newList[0].breeds);
          }
          setInput(result.data);
          setData(result.data);
          setEditorText(result.data.contents);
        })();
      }
    })();
  };

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const changeProfileImage = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        file: file,
        image: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  const handleSpeciesChange = (e) => {
    let newBreedList = speciesList.filter(
      (item) => item.id === Number(e.target.value)
    );
    setBreedList(newBreedList[0].breeds);
    setInput({
      ...input,
      speciesId: e.currentTarget.value,
      breedId: "",
    });
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
    }
    reload(Number(props.match.params.value));
  }, []);

  const onSubmit = () => {
    if (input.type !== "summary") {
      if (!input.title || input.title == "") {
        alert("제목을 입력해주세요");
        return false;
      } else if (input.title.length > 100) {
        alert("제목은 100자 이하로 작성해주세요");
        return false;
      }
      if (!editorText) {
        alert("내용을 입력해주세요");
        return false;
      }
    } else {
      if (!input.type || input.type == "") {
        alert("내용을 입력해주세요");
        return false;
      }
    }

    if (!input.breedId || input.breedId == "") {
      alert("품종을 선택해주세요");
      return false;
    }
    // if (!input.type || input.type == "") {
    //   alert("유형을 입력해주세요");
    //   return false;
    // }

    const bodyFormData = new FormData();
    // bodyFormData.set("id", input.id);
    if (input.type !== "summary") {
      bodyFormData.set("title", input.title);
      bodyFormData.set("contents", editorText);
    } else {
      bodyFormData.set("contents", input.contents);
    }

    bodyFormData.set("breedId", input.breedId);
    bodyFormData.set("type", input.type);

    editFormData(`admin/breedInfo/${input.id}`, bodyFormData).then(function (
      response
    ) {
      if (response.result) {
        alert("동물사전 정보 수정이 완료되었습니다.");
        history.push(`/admin/breedInfoList/1`);
      } else {
        alert(response.msg);
      }
    });
  };

  const onAdd = () => {
    if (input.type !== "summary") {
      if (!input.title || input.title == "") {
        alert("제목을 입력해주세요");
        return false;
      } else if (input.title.length > 100) {
        alert("제목은 100자 이하로 작성해주세요");
        return false;
      }
      if (!editorText) {
        alert("내용을 입력해주세요");
        return false;
      }
    } else {
      if (!input.type || input.type == "") {
        alert("내용을 입력해주세요");
        return false;
      }
    }
    if (!input.breedId || input.breedId == "") {
      alert("품종을 선택해주세요");
      return false;
    }

    const bodyFormData = new FormData();
    if (input.type !== "summary") {
      bodyFormData.set("title", input.title);
      bodyFormData.set("contents", editorText);
    } else {
      bodyFormData.set("contents", input.contents);
    }
    bodyFormData.set("breedId", input.breedId);
    bodyFormData.set("type", input.type);

    addFormData(`admin/breedInfo`, bodyFormData).then(function (response) {
      if (response.result) {
        alert("동물사전 정보 등록이 완료되었습니다.");
        history.push(`/admin/breedInfoList/1`);
      } else {
        alert(response);
      }
    });
  };

  const onRemove = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      deleteData(`admin/breedInfo`, input.id).then(function (response) {
        if (response.result) {
          alert("삭제성공");
          history.push(`/admin/breedInfoList/1`);
        } else {
          alert("삭제실패");
        }
      });
    }
  };

  /**
   *
   * 서머노트 관련 함수
   */
  const onChange = (content) => {
    setEditorText(content);
  };

  const onImageUpload = (images, insertImage) => {
    const bodyFormData = new FormData();
    for (let i = 0; i < images.length; i++) {
      bodyFormData.set("files", images[i]);
      addFileUpload(`admin/image-insert`, bodyFormData).then(function (
        response
      ) {
        console.log(response);
        insertImage(response.data.filePath);
      });
    }
  };

  const onInit = (note) => {
    note.reset();
    const regex = /(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/i;
    if (data) {
      console.log("tset", data.content.match(regex));
      if (data && data.content.match(regex) !== null) {
        note.replace(data && data.content);
      } else {
        note.insertText(data && data.content);
      }
    }
  };

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            {input && (
              <Card
                title={
                  (props.match.params.value !== "add" && "동물사전 정보") ||
                  (props.match.params.value === "add" && "동물사전 등록")
                }
                content={
                  <form onSubmit={onSubmit}>
                    {props.match.params.value !== "add" && (
                      <>
                        <FormInputs
                          ncols={["col-md-2", "col-md-5", "col-md-5"]}
                          properties={[
                            {
                              label: "번호",
                              type: "text",
                              bsClass: "form-control",
                              name: "id",
                              readOnly: true,
                              value: input.id || "",
                              style: { cursor: "initial" },
                            },
                            {
                              label: "등록일",
                              type: "text",
                              bsClass: "form-control",
                              name: "createdAt",
                              readOnly: true,
                              value:
                                Moment(input.createdAt).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                ) || "",
                              style: { cursor: "initial" },
                            },
                            {
                              label: "수정일",
                              type: "text",
                              bsClass: "form-control",
                              name: "updatedAt",
                              readOnly: true,
                              value:
                                Moment(input.updatedAt).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                ) || "",
                              style: { cursor: "initial" },
                            },
                          ]}
                        />
                        <FormInputs
                          ncols={["col-md-4", "col-md-4", "col-md-4"]}
                          properties={[
                            {
                              label: "종",
                              type: "select",
                              bsClass: "form-control",
                              name: "speciesId",
                              className: "infoSelect",
                              onChange: (e) => handleSpeciesChange(e),
                              // disabled: true,
                              value: input.speciesId,
                              style: { cursor: "initial" },
                              option: (
                                <>
                                  {speciesList &&
                                    speciesList.map((item, index) => {
                                      return (
                                        <option
                                          value={item.id}
                                          key={`species${index}`}
                                        >
                                          {item.nameKr}
                                        </option>
                                      );
                                    })}
                                </>
                              ),
                            },
                            {
                              label: "품종",
                              type: "select",
                              bsClass: "form-control",
                              name: "breedId",
                              className: "infoSelect",
                              onChange: (e) => handleInputChange(e),
                              // disabled: true,
                              value: input.breedId || "",
                              style: { cursor: "initial" },
                              option: (
                                <>
                                  <option value="" key="none">
                                    선택
                                  </option>
                                  {breedList &&
                                    breedList.map((item, index) => {
                                      return (
                                        <option
                                          value={item.id}
                                          key={`breed${index}`}
                                        >
                                          {item.nameKr}
                                        </option>
                                      );
                                    })}
                                </>
                              ),
                            },
                            {
                              label: "유형",
                              type: "select",
                              bsClass: "form-control",
                              name: "type",
                              className: "infoSelect",
                              // readOnly: true,
                              onChange: (e) => handleInputChange(e),
                              value: input.type || "",
                              style: { cursor: "initial" },
                              option: (
                                <>
                                  <option value={`summary`} key={`summary`}>
                                    요약
                                  </option>
                                  <option value={`overview`} key={`overview`}>
                                    개요
                                  </option>
                                  <option value={`look`} key={`look`}>
                                    외형정보
                                  </option>
                                  <option value={`basic`} key={`basic`}>
                                    기본정보
                                  </option>
                                  <option value={`breeding`} key={`breeding`}>
                                    사육정보
                                  </option>
                                  <option value={`managing`} key={`managing`}>
                                    관리정보
                                  </option>
                                </>
                              ),
                            },
                          ]}
                        />
                      </>
                    )}
                    {props.match.params.value === "add" && (
                      <FormInputs
                        ncols={["col-md-4", "col-md-4", "col-md-4"]}
                        properties={[
                          {
                            label: "종",
                            type: "select",
                            bsClass: "form-control",
                            name: "speciesId",
                            className: "infoSelect",
                            onChange: (e) => handleSpeciesChange(e),
                            // disabled: true,
                            value: input.speciesId,
                            style: { cursor: "initial" },
                            option: (
                              <>
                                {speciesList &&
                                  speciesList.map((item, index) => {
                                    return (
                                      <option
                                        value={item.id}
                                        key={`species${index}`}
                                      >
                                        {item.nameKr}
                                      </option>
                                    );
                                  })}
                              </>
                            ),
                          },
                          {
                            label: "품종",
                            type: "select",
                            bsClass: "form-control",
                            name: "breedId",
                            className: "infoSelect",
                            onChange: (e) => handleInputChange(e),
                            // disabled: true,
                            value: input.breedId || "",
                            style: { cursor: "initial" },
                            option: (
                              <>
                                <option value="" key="none">
                                  선택
                                </option>
                                {breedList &&
                                  breedList.map((item, index) => {
                                    return (
                                      <option
                                        value={item.id}
                                        key={`breed${index}`}
                                      >
                                        {item.nameKr}
                                      </option>
                                    );
                                  })}
                              </>
                            ),
                          },
                          {
                            label: "유형",
                            type: "select",
                            bsClass: "form-control",
                            name: "type",
                            className: "infoSelect",
                            // readOnly: true,
                            onChange: (e) => handleInputChange(e),
                            value: input.type || "",
                            style: { cursor: "initial" },
                            option: (
                              <>
                                <option value={`summary`} key={`summary`}>
                                  요약
                                </option>
                                <option value={`overview`} key={`overview`}>
                                  개요
                                </option>
                                <option value={`look`} key={`look`}>
                                  외형정보
                                </option>
                                <option value={`basic`} key={`basic`}>
                                  기본정보
                                </option>
                                <option value={`breeding`} key={`breeding`}>
                                  사육정보
                                </option>
                                <option value={`managing`} key={`managing`}>
                                  관리정보
                                </option>
                              </>
                            ),
                          },
                        ]}
                      />
                    )}
                    {input.type !== "summary" && (
                      <>
                        <FormInputs
                          ncols={["col-md-12"]}
                          properties={[
                            {
                              label: "제목",
                              type: "text",
                              bsClass: "form-control",
                              name: "title",
                              // readOnly: true,
                              onChange: (e) => handleInputChange(e),
                              value: input.title || "",
                              style: { cursor: "initial" },
                            },
                          ]}
                        />
                        <Row>
                          <Col md={12}>
                            <FormGroup controlId="formControlsTextarea">
                              <ControlLabel>내용</ControlLabel>
                              <ReactSummernote
                                options={{
                                  lang: "ko-KR",
                                  height: 350,
                                  dialogsInBody: true,
                                  toolbar: [
                                    ["style", ["style"]],
                                    ["font", ["bold", "underline", "clear"]],
                                    ["fontname", ["fontname"]],
                                    ["para", ["ul", "ol", "paragraph"]],
                                    ["table", ["table"]],
                                    ["insert", ["link", "picture", "video"]],
                                    ["view", ["fullscreen", "codeview"]],
                                  ],
                                }}
                                value={editorText}
                                onChange={onChange}
                                onImageUpload={onImageUpload}
                                onInit={onInit}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    )}
                    {input.type === "summary" && (
                      <FormGroup controlId="formControlsTextarea">
                        <ControlLabel>내용</ControlLabel>
                        <FormControl
                          rows="4"
                          componentClass="textarea"
                          bsClass="form-control"
                          onChange={(e) => handleInputChange(e)}
                          name="contents"
                          // readOnly={true}
                          value={
                            input.contents &&
                            input.contents != "undefined" &&
                            input.contents != "null"
                              ? input.contents
                              : ""
                          }
                          style={{ resize: "none", cursor: "initial" }}
                        />
                      </FormGroup>
                    )}
                    {/* <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "태그",
                          type: "text",
                          bsClass: "form-control",
                          name: "tags",
                          // readOnly: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.tags || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    /> */}
                    {/* <FormGroup controlId="formControlsTextarea">
                      <ControlLabel>내용</ControlLabel>
                      <FormControl
                        rows="4"
                        componentClass="textarea"
                        bsClass="form-control"
                        onChange={(e) => handleInputChange(e)}
                        name="contents"
                        value={input.contents || ""}
                        style={{ resize: "none", cursor: "initial" }}
                      />
                    </FormGroup> */}

                    {props.match.params.value === "add" && (
                      <Button
                        bsStyle="info"
                        style={{ marginLeft: "1%" }}
                        pullRight
                        fill
                        onClick={(e) => onAdd(e)}
                      >
                        등록
                      </Button>
                    )}
                    {props.match.params.value !== "add" && (
                      <>
                        <Button
                          bsStyle="info"
                          style={{ marginLeft: "1%" }}
                          pullRight
                          fill
                          onClick={(e) => onSubmit(e)}
                        >
                          수정
                        </Button>
                        <Button
                          bsStyle="warning"
                          style={{ marginLeft: "1%" }}
                          pullRight
                          fill
                          onClick={(e) => onRemove(e)}
                        >
                          삭제
                        </Button>
                        <Button
                          bsStyle="success"
                          style={{ marginLeft: "1%" }}
                          pullRight
                          fill
                          onClick={(e) => history.goBack()}
                        >
                          목록
                        </Button>
                      </>
                    )}
                    <div className="clearfix" />
                  </form>
                }
              />
            )}
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default BreedInfoDetail;
