import React, { useState, useEffect } from "react";
import { fetchData, deleteData, editFormData } from "modules/api.js";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import Pagination from "@material-ui/lab/Pagination";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import {
	style,
	buttonDivStyle,
	buttonStyle1,
	buttonStyle2,
	buttonStyle3,
	BootstrapInput,
	useStyles,
} from "./style.jsx";

function CommentList(props) {
	const userThArray = ["번호", "원글번호", "내용", "작성자", "등록일", ""];
	const classes = useStyles();
	const [dataList, setDataList] = useState();
	const [total, setTotal] = useState(1);
	const history = useHistory();
	const [searchData, setSearchData] = useState({
		searchWord: "",
		type: "",
		searchType: "",
	});
	const [page, setPage] = useState(1);
	const [totalItem, setTotalItem] = useState();
	const [editItem, setEditItem] = useState();
	const [isEditMode, setIsEditMode] = useState();

	const reload = (value) => {
		if (value === undefined || value === null) {
			value = page;
		}

		(async function anyNameFunction() {
			const result = await fetchData(
				// `admin/brandList?page=${value}`
				`admin/commentList?page=${value}&searchWord=${searchData.searchWord}&searchType=${searchData.searchType}`
			);
			console.log(result);
			setTotalItem(result.data.count);
			setTotal(Math.ceil(result.data.count / 10));
			setDataList(result.data.rows);
		})();
	};

	useEffect(() => {
		const auth = localStorage.getItem("auth") || "";
		if (auth === "" || auth === undefined || auth === null) {
			history.push("/login");
		}
		setPage(Number(props.match.params.value));
		reload(Number(props.match.params.value));
	}, []);

	const pageChange = (event, value) => {
		setPage(value);
		reload(value);
		history.push(`/admin/commentList/${value}`);
	};

	const searchChange = (event) => {
		if (window.event.keyCode === 13) {
			reload(1);
		}
		setSearchData({
			...searchData,
			[event.target.name]: event.target.value,
		});
	};

	const onChangeEComment = (e) => {
		setEditItem({
			...editItem,
			contents: e.target.value,
		});
	};

	const onSearch = () => {
		setPage(1);
		reload(1);
	};

	const onEdit = () => {
		if (window.confirm("수정하시겠습니까?")) {
			const bodyFormData = new FormData();
			// bodyFormData.set("id", input.id);
			// bodyFormData.set("id", editItem.id);
			bodyFormData.set("contents", editItem.contents);

			editFormData(`admin/comment/${editItem.id}`, bodyFormData).then(function (
				response
			) {
				if (response.result) {
					alert("수정 성공");
					reload();
					setIsEditMode(false);
				} else {
					alert("수정실패");
				}
			});
		}
	};

	const onDel = (id) => {
		if (window.confirm("삭제하시겠습니까?")) {
			// deleteData(`admin/comment`, id).then(function (response) {
			//   if (response.result) {
			//     alert("삭제성공");
			//     reload();
			//   } else {
			//     console.log(response);
			//     alert("삭제실패");
			//   }
			// });
			const bodyFormData = new FormData();
			bodyFormData.set("contents", "삭제된 댓글입니다.");

			editFormData(`admin/comment/${id}`, bodyFormData).then(function (
				response
			) {
				if (response.result) {
					alert("삭제성공");
					reload();
				} else {
					alert("삭제실패");
				}
			});
		}
	};

	return (
		<div className="content">
			<Grid fluid>
				<Row>
					<Col md={12}>
						<Card
							title="댓글 관리"
							ctTableFullWidth
							ctTableResponsive
							content={
								<div>
									<div style={buttonDivStyle}>
										<FormControl className={classes.margin}>
											<NativeSelect
												id="demo-customized-select-native"
												value={searchData.searchType || ""}
												onChange={(e) => searchChange(e)}
												input={<BootstrapInput />}
												name="searchType"
												style={{ font: "initial" }}
											>
												<option value="0">전체</option>
												<option value="1">원글번호</option>
												<option value="2">작성자</option>
												<option value="3">내용</option>
											</NativeSelect>
										</FormControl>
										<FormControl className={classes.margin}>
											<BootstrapInput
												id="demo-customized-textbox"
												name="searchWord"
												onChange={(e) => searchChange(e)}
												value={searchData.searchWord || ""}
												onKeyUp={(e) => searchChange(e)}
											/>
										</FormControl>
										<Button
											variant="outlined"
											style={buttonStyle1}
											onClick={(e) => onSearch(e)}
										>
											검색
										</Button>
									</div>
									<Table striped hover>
										<thead>
											<tr>
												{userThArray.map((prop, key) => {
													return <th key={key}>{prop}</th>;
												})}
											</tr>
										</thead>
										<tbody>
											{dataList && dataList.length > 0 ? (
												dataList.map((data, index) => {
													// data.no = totalItem - (page - 1) * 20 - index;
													// console.log(dataList.length);
													return (
														<tr
															key={index}
															// onClick={(e) =>
															//   history.push(`/admin/board/${data.id}`)
															// }
														>
															<td
																style={{
																	verticalAlign: "middle",
																}}
															>
																{data.id}
															</td>
															<td
																style={{
																	verticalAlign: "middle",
																}}
															>
																{data.contentsId}
															</td>
															<td
																style={{
																	verticalAlign: "middle",
																}}
															>
																{data.contents}
															</td>
															<td
																style={{
																	verticalAlign: "middle",
																}}
															>
																{data.user ? data.user.name : ""}
															</td>
															<td
																style={{
																	verticalAlign: "middle",
																}}
															>
																{(data.createdAt &&
																	Moment(data.createdAt).format(
																		"YYYY-MM-DD HH:mm:ss"
																	)) ||
																	""}
															</td>
															<td
																style={{
																	width: "15%",
																}}
															>
																<div style={{ display: "flex" }}>
																	<Button
																		variant="outlined"
																		style={buttonStyle3}
																		onClick={(e) => {
																			setIsEditMode(true);
																			setEditItem(data);
																		}}
																	>
																		수정
																	</Button>
																	<Button
																		variant="outlined"
																		style={buttonStyle3}
																		onClick={(e) => {
																			onDel(data.id);
																		}}
																	>
																		삭제
																	</Button>
																</div>
															</td>
														</tr>
													);
												})
											) : (
												<tr>
													<td colSpan="6" style={{ textAlign: "center" }}>
														검색결과가 없습니다
													</td>
												</tr>
											)}
										</tbody>
										{isEditMode && (
											<tbody>
												<tr>
													<td
														style={{
															verticalAlign: "middle",
														}}
													>
														{editItem.id}
													</td>
													<td
														style={{
															verticalAlign: "middle",
														}}
													>
														{editItem.contentsId}
													</td>
													{/* <td style={{
                                  verticalAlign: "middle",
                                }}>{editItem.contents}</td> */}
													<td style={{ textAlign: "center" }}>
														<BootstrapInput
															id="demo-customized-textbox"
															name="code"
															placeholder="내용"
															value={editItem.contents}
															style={{ width: "100%" }}
															onChange={(e) => onChangeEComment(e)}
														/>
													</td>
													<td
														style={{
															verticalAlign: "middle",
														}}
													>
														{editItem.user ? editItem.user.name : ""}
													</td>
													<td
														style={{
															verticalAlign: "middle",
														}}
													>
														{(editItem.createdAt &&
															Moment(editItem.createdAt).format(
																"YYYY-MM-DD HH:mm:ss"
															)) ||
															""}
													</td>
													<td
														style={{
															justifyContent: "center",
														}}
													>
														<Button
															variant="outlined"
															style={buttonStyle2}
															onClick={(e) => onEdit(e)}
														>
															수정
														</Button>
													</td>
												</tr>
											</tbody>
										)}
									</Table>
								</div>
							}
						/>
						<Pagination
							count={total}
							page={page}
							size="large"
							onChange={pageChange}
							variant="outlined"
							shape="rounded"
							style={style}
						/>
					</Col>
				</Row>
			</Grid>
		</div>
	);
}

export default CommentList;
