import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { useHistory } from "react-router-dom";
// import { UserCard } from "views/users/node_modules/components/UserCard/UserCard.jsx.js";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  fetchData,
  editFormData,
  addFileUpload,
  deleteData,
} from "modules/api.js";
import { useStyles } from "./style.jsx";
import Moment from "moment";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-ko-KR";

function InfoEditDetail(props) {
  const [input, setInput] = useState();
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState();
  const reload = () => {
    (async function anyNameFunction() {
      const result = await fetchData(
        `admin/infoEdit/${props.match.params.value}`
      );
      console.log(result);
      setInput(result.data);
      setData(result.data);
    })();
  };

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
      // return false;
    }
    reload();
  }, []);

  // const onSubmit = () => {

  //   editFormData(`admin/infoEdit/${input.id}`, bodyFormData).then(function (
  //     response
  //   ) {
  //     console.log(response);
  //     if (response.result) {

  //       alert("신고 수정이 완료되었습니다.");
  //       history.push(`/admin/infoEditList/1`);
  //     } else {
  //       alert(response);
  //     }
  //   });
  // };

  return (
    <div className="content">
      <Grid fluid>
        {data && (
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col md={7}>
              <Card
                title="정보수정제안 상세정보"
                content={
                  <>
                    <FormInputs
                      ncols={["col-md-3", "col-md-3", "col-md-6"]}
                      properties={[
                        {
                          label: "번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "id",
                          disabled: true,
                          value: input.id || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "브랜드명",
                          type: "text",
                          bsClass: "form-control",
                          name: "name",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.brand ? input.brand.name : "" || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "등록일",
                          type: "text",
                          bsClass: "form-control",
                          name: "createdAt",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.createdAt
                            ? Moment(input.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-8", "col-md-4"]}
                      properties={[
                        {
                          label: "제목",
                          type: "text",
                          bsClass: "form-control",
                          name: "title",
                          readOnly: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.title || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "작성자",
                          type: "text",
                          bsClass: "form-control",
                          name: "userId",
                          onChange: (e) => handleInputChange(e),
                          disabled: true,
                          value: input.user ? input.user.name : "" || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <FormGroup controlId="formControlsTextarea">
                      <ControlLabel>내용</ControlLabel>
                      <FormControl
                        rows="4"
                        componentClass="textarea"
                        bsClass="form-control"
                        onChange={(e) => handleInputChange(e)}
                        name="contents"
                        readOnly={true}
                        value={
                          input.contents &&
                          input.contents != "undefined" &&
                          input.contents != "null"
                            ? input.contents
                            : ""
                        }
                        style={{ resize: "none", cursor: "initial" }}
                      />
                    </FormGroup>

                    {/* <Button
                      bsStyle="info"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={onSubmit}
                    >
                      수정
                    </Button> */}
                    <Button
                      bsStyle="success"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={(e) => history.goBack()}
                    >
                      목록
                    </Button>
                    <div className="clearfix" />
                  </>
                }
              />
            </Col>
          </Row>
        )}
      </Grid>
    </div>
  );
}

export default InfoEditDetail;
