import React from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { Formik } from "formik";

export class TagInput extends React.Component {
  constructor(props) {
    super();
    this.state = {
      tags: props.list,
      type: props.type,
    };
  }

  render() {
    return (
      <>
        {!this.props.type && (
          <>
            <Formik
              initialValues={this.state}
              onSubmit={(e) => this.handleSubmit()}
              getList={this.props.list}
              render={({
                values,
                errors,
                touched,
                status,
                dirty,
                handleChange,
                getList,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                handleReset,
                setTouched,
                setFieldValue,
              }) => (
                <div style={{ marginBottom: "0.5%" }}>
                  {/* <Form noValidate name="simpleForm"> */}
                  <TagsInput
                    name="tags"
                    value={values.tags}
                    placeholder="태그 추가"
                    onChange={(tags) => {
                      setFieldValue("tags", tags);
                      this.setState({ tags });
                      console.log(tags);
                      this.props.onChange(tags);
                    }}
                  />
                  {/* </Form> */}
                </div>
              )}
            />
            {/* 
            <Button
              bsStyle="info"
              style={{ marginLeft: "1%" }}
              pullRight
              fill
              onClick={(e) => this.props.onClick(this.state)}
            >
              저장
            </Button> */}
          </>
        )}
        {this.props.type && (
          <div
            className="form-control"
            style={{
              cursor: "initial",
              backgroundColor: "#f5f5f5",
              padding: "4px 12px",
            }}
          >
            {this.props.list.map((item, index) => {
              return (
                <span>
                  <span
                    class="react-tagsinput-tag"
                    style={{
                      border: "1px solid #cccccc8a",
                      backgroundColor: "#cccccc8a",
                      color: "#888888",
                      padding: "4px 12px",
                    }}
                  >
                    {item}
                  </span>
                </span>
              );
            })}
          </div>
        )}
      </>
    );
  }
}

export default TagInput;
