import React, { useState, useEffect } from "react";
import {
  fetchData,
  editFormData,
  addFormData,
  deleteData,
} from "modules/api.js";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import {
  buttonDivStyle,
  buttonStyle1,
  buttonStyle2,
  buttonStyle3,
  BootstrapInput,
} from "./style.jsx";

function ColorList() {
  const thArray = ["번호", "코드", "색상", ""];
  const [dataList, setDataList] = useState();
  const history = useHistory();
  // const [page, setPage] = useState(1);
  const [ifAddEye, setIfAddEye] = useState(false);
  const [ifAddCoat, setIfAddCoat] = useState(false);

  const [addEyeData, setAddEyeData] = useState({
    type: "eye",
    code: null,
  });
  const [addCoatData, setAddCoatData] = useState({
    type: "coat",
    code: null,
  });
  const [modEyeData, setModEyeData] = useState();
  const [modCoatData, setModCoatData] = useState();
  const reload = (value) => {
    // if (value === undefined || value === null) {
    //   value = page;
    // }
    (async function anyNameFunction() {
      const result = await fetchData(`user/colorList`);
      console.log(result);
      setDataList(result.data);
    })();
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
    }
    // setPage(1);
    reload();
  }, []);

  const onChangeEye = (e) => {
    if (modEyeData) {
      console.log("눈색수정");
      setModEyeData({
        ...modEyeData,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    } else {
      console.log("눈색등록");
      setAddEyeData({
        ...addEyeData,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const onChangeCoat = (e) => {
    if (modCoatData) {
      setModCoatData({
        ...modCoatData,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    } else {
      setAddCoatData({
        ...addCoatData,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const onDelCoat = (id) => {
    //삭제api
    (async function edit() {
      const response = await deleteData(`admin/color`, id);
      if (response.result) {
        setModCoatData();
        reload();
      }
    })();
  };

  const onDelEye = (id) => {
    console.log(id);
    //삭제api
    (async function edit() {
      const response = await deleteData(`admin/color`, id);
      if (response.result) {
        setModEyeData();
        reload();
      }
    })();
  };

  const onEditCoat = (e) => {
    console.log("코트색수정확정");
    const param = {
      code: modCoatData.code,
      type: modCoatData.type,
    };
    //수정api
    (async function edit() {
      const response = await editFormData(
        `admin/color/${modCoatData.id}`,
        param
      );
      if (response.result) {
        setModCoatData();
        reload();
      }
    })();
  };

  const onEditEye = (e) => {
    console.log("눈색수정확정");
    console.log(modEyeData);
    const param = {
      code: modEyeData.code,
      type: modEyeData.type,
    };
    //수정api
    (async function edit() {
      const response = await editFormData(
        `admin/color/${modEyeData.id}`,
        param
      );
      if (response.result) {
        setModEyeData();
        reload();
      }
    })();
  };

  const onAddEye = (e) => {
    console.log("눈색등록확정");
    const param = {
      code: addEyeData.code,
      type: addEyeData.type,
    };
    (async function add() {
      const response = await addFormData(`admin/color`, param);
      if (response.result) {
        setIfAddEye(false);
        reload();
      }
    })();
  };

  const onAddCoat = (e) => {
    console.log("코트색등록확정");
    const param = {
      code: addCoatData.code,
      type: addCoatData.type,
    };
    (async function add() {
      const response = await addFormData(`admin/color`, param);
      if (response.result) {
        setIfAddCoat(false);
        reload();
      }
    })();
  };

  return (
    <div className="content">
      <Grid fluid>
        {dataList && (
          <Row>
            <Col md={12}>
              <Card
                title="코트 색상"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div>
                    <div style={buttonDivStyle}>
                      {/* {!ifAdd && ( */}
                      <Button
                        variant="outlined"
                        style={buttonStyle1}
                        onClick={(e) => {
                          if (
                            dataList.coatList &&
                            dataList.coatList.length > 6
                          ) {
                            alert("최대 7개까지 등록할 수 있습니다.");
                            return false;
                          } else {
                            setModCoatData();
                            setIfAddCoat(true);
                          }
                        }}
                      >
                        등록
                      </Button>
                      {/* )} */}
                    </div>

                    <Table striped hover>
                      <thead>
                        <tr>
                          {thArray.map((prop, key) => {
                            return <th key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {dataList.coatList.map((data, index) => {
                          return (
                            <tr key={`$${data.id}`}>
                              <td
                                style={{
                                  width: "15%",
                                  verticalAlign: "middle",
                                }}
                              >
                                {index + 1}
                              </td>
                              <td
                                style={{
                                  width: "35%",
                                  verticalAlign: "middle",
                                }}
                              >
                                {data.code}
                              </td>
                              <td
                                style={{
                                  width: "35%",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: "10%",
                                    width: "25px",
                                    height: "25px",
                                    backgroundColor: `${data.code}`,
                                  }}
                                />
                              </td>
                              <td
                                style={{
                                  width: "15%",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <Button
                                    variant="outlined"
                                    style={buttonStyle3}
                                    onClick={(e) => {
                                      setIfAddCoat(false);
                                      setModCoatData(data);
                                    }}
                                  >
                                    수정
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    style={buttonStyle3}
                                    onClick={(e) => {
                                      onDelCoat(data.id);
                                    }}
                                  >
                                    삭제
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      {ifAddCoat && (
                        <tbody>
                          <tr>
                            <td colSpan="2" style={{ textAlign: "center" }}>
                              <BootstrapInput
                                id="demo-customized-textbox"
                                name="code"
                                placeholder="색상코드"
                                onChange={(e) => onChangeCoat(e)}
                              />
                            </td>
                            <td
                              style={{
                                width: "35%",
                                verticalAlign: "middle",
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: "10%",
                                  width: "25px",
                                  height: "25px",
                                  backgroundColor: `${addCoatData.code}`,
                                }}
                              />
                            </td>

                            <td
                              style={{
                                width: "15%",
                              }}
                            >
                              <Button
                                variant="outlined"
                                style={buttonStyle2}
                                onClick={(e) => onAddCoat(e)}
                              >
                                등록
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      )}
                      {modCoatData && (
                        <tbody>
                          <tr>
                            <td colSpan="2" style={{ textAlign: "center" }}>
                              <BootstrapInput
                                id="demo-customized-textbox"
                                name="code"
                                placeholder="색상코드"
                                value={modCoatData.code || ""}
                                onChange={(e) => onChangeCoat(e)}
                              />
                            </td>
                            <td
                              style={{
                                width: "35%",
                                verticalAlign: "middle",
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: "10%",
                                  width: "25px",
                                  height: "25px",
                                  backgroundColor: `${modCoatData.code}`,
                                }}
                              />
                            </td>
                            <td
                              style={{
                                width: "15%",
                              }}
                            >
                              <Button
                                variant="outlined"
                                style={buttonStyle2}
                                onClick={(e) => onEditCoat(e)}
                              >
                                수정
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                }
              />
              <Card
                title="눈 색상"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div>
                    <div style={buttonDivStyle}>
                      {/* {!ifAdd && ( */}
                      <Button
                        variant="outlined"
                        style={buttonStyle1}
                        onClick={(e) => {
                          if (dataList.eyeList && dataList.eyeList.length > 6) {
                            alert("최대 7개까지 등록할 수 있습니다.");
                            return false;
                          } else {
                            setModEyeData();
                            setIfAddEye(true);
                          }
                        }}
                      >
                        등록
                      </Button>
                      {/* )} */}
                    </div>

                    <Table striped hover>
                      <thead>
                        <tr>
                          {thArray.map((prop, key) => {
                            return <th key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {dataList.eyeList.map((data, index) => {
                          return (
                            <tr key={`${data.id}`}>
                              <td
                                style={{
                                  width: "15%",
                                  verticalAlign: "middle",
                                }}
                              >
                                {index + 1}
                              </td>
                              <td
                                style={{
                                  width: "35%",
                                  verticalAlign: "middle",
                                }}
                              >
                                {data.code}
                              </td>
                              <td
                                style={{
                                  width: "35%",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: "10%",
                                    width: "25px",
                                    height: "25px",
                                    backgroundColor: `${data.code}`,
                                  }}
                                />
                              </td>
                              <td
                                style={{
                                  width: "15%",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <Button
                                    variant="outlined"
                                    style={buttonStyle3}
                                    onClick={(e) => {
                                      setIfAddEye(false);
                                      setModEyeData(data);
                                    }}
                                  >
                                    수정
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    style={buttonStyle3}
                                    onClick={(e) => {
                                      onDelEye(data.id);
                                    }}
                                  >
                                    삭제
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      {ifAddEye && (
                        <tbody>
                          <tr>
                            <td colSpan="2" style={{ textAlign: "center" }}>
                              <BootstrapInput
                                id="demo-customized-textbox"
                                name="code"
                                placeholder="색상코드"
                                onChange={(e) => onChangeEye(e)}
                              />
                            </td>
                            <td
                              style={{
                                width: "35%",
                                verticalAlign: "middle",
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: "10%",
                                  width: "25px",
                                  height: "25px",
                                  backgroundColor: `${addEyeData.code}`,
                                }}
                              />
                            </td>

                            <td
                              style={{
                                width: "15%",
                              }}
                            >
                              <Button
                                variant="outlined"
                                style={buttonStyle2}
                                onClick={(e) => onAddEye(e)}
                              >
                                등록
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      )}

                      {modEyeData && (
                        <tbody>
                          <tr>
                            <td colSpan="2" style={{ textAlign: "center" }}>
                              <BootstrapInput
                                id="demo-customized-textbox"
                                name="code"
                                placeholder="색상코드"
                                value={modEyeData.code || ""}
                                onChange={(e) => onChangeEye(e)}
                              />
                            </td>
                            <td
                              style={{
                                width: "35%",
                                verticalAlign: "middle",
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: "10%",
                                  width: "25px",
                                  height: "25px",
                                  backgroundColor: `${modEyeData.code}`,
                                }}
                              />
                            </td>
                            <td
                              style={{
                                width: "15%",
                              }}
                            >
                              <Button
                                variant="outlined"
                                style={buttonStyle2}
                                onClick={(e) => onEditEye(e)}
                              >
                                수정
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                }
              />
            </Col>
          </Row>
        )}
      </Grid>
    </div>
  );
}

export default ColorList;
