import React, { useState, useEffect } from "react";
import { fetchData } from "modules/api.js";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import Pagination from "@material-ui/lab/Pagination";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import {
  style,
  buttonDivStyle,
  buttonStyle1,
  BootstrapInput,
  useStyles,
} from "./style.jsx";

function CalendarList(props) {
  const userThArray = [
    "번호",
    "회원",
    "펫",
    "분류",
    "상세",
    "비용",
    "시간",
    "거리",
    "예정일",
    "등록일",
    // "상태",
  ];
  const classes = useStyles();
  const [dataList, setDataList] = useState();
  const [total, setTotal] = useState(1);
  const history = useHistory();
  const [searchData, setSearchData] = useState({
    type1: "",
  });
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState();

  const reload = (value) => {
    if (value === undefined || value === null) {
      value = page;
    }

    (async function anyNameFunction() {
      const result = await fetchData(
        `admin/calendarList?page=${value}&type1=${searchData.type1}`
      );
      console.log(result);
      setTotalItem(result.data.count);
      setTotal(Math.ceil(result.data.count / 10));
      setDataList(result.data.rows);
    })();
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
    }
    setPage(Number(props.match.params.value));
    reload(Number(props.match.params.value));
  }, []);

  const pageChange = (event, value) => {
    setPage(value);
    reload(value);
    history.push(`/admin/calendarList/${value}`);
  };

  const searchChange = (event) => {
    if (window.event.keyCode === 13) {
      reload(1);
    }
    setSearchData({
      ...searchData,
      [event.target.name]: event.target.value,
    });
  };

  const onSearch = () => {
    setPage(1);
    reload(1);
  };

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="캘린더 관리"
              ctTableFullWidth
              ctTableResponsive
              content={
                <div>
                  <div style={buttonDivStyle}>
                    <FormControl className={classes.margin}>
                      <NativeSelect
                        id="demo-customized-select-native"
                        onChange={(e) => searchChange(e)}
                        input={<BootstrapInput />}
                        name="type1"
                        style={{ font: "initial" }}
                      >
                        <>
                          <option value="">전체</option>
                          <option value="의료" key="의료">
                            의료
                          </option>
                          <option value="미용" key="미용">
                            미용
                          </option>
                          <option value="산책" key="산책">
                            산책
                          </option>
                          <option value="기타" key="기타">
                            기타
                          </option>
                        </>
                      </NativeSelect>
                    </FormControl>
                    <Button
                      variant="outlined"
                      style={buttonStyle1}
                      onClick={(e) => onSearch(e)}
                    >
                      검색
                    </Button>
                  </div>
                  <Table striped hover>
                    <thead>
                      <tr>
                        {userThArray.map((prop, key) => {
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {dataList && dataList.length > 0 ? (
                        dataList.map((data, index) => {
                          // data.no = totalItem - (page - 1) * 20 - index;
                          // console.log(dataList.length);
                          return (
                            <tr key={index}>
                              <td>{data.id}</td>
                              <td>{data.user ? data.user.name : ""}</td>
                              <td>{data.pet ? data.pet.name : ""}</td>
                              <td>{data.type1}</td>
                              <td>{data.detail}</td>
                              <td>{data.cost}</td>
                              <td>{data.time}</td>
                              <td>{data.distance}</td>
                              <td>
                                {Moment(data.d_day).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </td>
                              <td>
                                {(data.createdAt &&
                                  Moment(data.createdAt).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )) ||
                                  ""}
                              </td>
                              {/* <td>
                                {data.deletedAt == null ? "가입" : "탈퇴"}
                              </td> */}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="6" style={{ textAlign: "center" }}>
                            검색결과가 없습니다
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              }
            />
            <Pagination
              count={total}
              page={page}
              size="large"
              onChange={pageChange}
              variant="outlined"
              shape="rounded"
              style={style}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default CalendarList;
