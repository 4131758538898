import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { useHistory } from "react-router-dom";
// import { UserCard } from "views/users/node_modules/components/UserCard/UserCard.jsx.js";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  fetchData,
  editFormData,
  addFileUpload,
  deleteData,
  addFormData,
} from "modules/api.js";
import { useStyles, buttonDivStyle, BootstrapInput } from "./style.jsx";
import Moment from "moment";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-ko-KR";
import DaumPostcode from "react-daum-postcode";
// import daum  from 'daum-map-api';

function BrandDetail(props) {
  const [addressModalShow, setAddressModalShow] = useState(false);
  const [input, setInput] = useState();
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState();
  const [editorText, setEditorText] = useState("");
  const [galleryList, setGalleryList] = useState();
  const [snsList, setSnsList] = useState([]);
  const [youtube, setYoutube] = useState();
  const [youtubeId, setYoutubeId] = useState();
  const [youtubeYn, setYoutubeYn] = useState(false);
  const [facebookYn, setFacebookYn] = useState(false);
  const [instagramYn, setInstagramYn] = useState(false);
  const [facebook, setFacebook] = useState();
  const [facebookId, setFacebookId] = useState();
  const [instagram, setInstagram] = useState();
  const [instagramId, setInstagramId] = useState();
  const [changeSns, setChangeSns] = useState();
  const [fileList, setFileList] = useState([]);
  const reload = () => {
    (async function anyNameFunction() {
      const result = await fetchData(`admin/brand/${props.match.params.value}`);
      console.log(result);
      setInput(result.data);
      setData(result.data);
      setEditorText(result.data.contents);
      setGalleryList(result.data.gallery);

      // setSnsList(result.data.BrandSns);
      if (result.data.BrandSns.length > 0) {
        result.data.BrandSns.map((item, index) => {
          console.log(item);
          if (item.type === "youtube") {
            setYoutubeId(item.id);
            setYoutube(item.link);
            setYoutubeYn(true);
          } else if (item.type === "facebook") {
            setFacebookId(item.id);
            setFacebook(item.link);
            setFacebookYn(true);
          } else if (item.type === "instagram") {
            setInstagramId(item.id);
            setInstagram(item.link);
            setInstagramYn(true);
          }
        });
      }
    })();
  };

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };
  const handleComplete = (postCodeData) => {
    console.log(postCodeData);
    setInput({
      ...input,
      address: postCodeData.address,
      // latitude:
    });

    // daum.map('서울역', function(res) {
    //   console.log(res)
    // });

    // const geocoder = new daum.map.services.Geocoder();

    // geocoder.addressSearch(address, (result, status) =>{
    //     if(status === daum.maps.services.Status.OK){
    //         const { x, y } = result[0];
    //         resolve({ lat: y, lon: x })
    //     }else{
    //         reject();
    //     }
    // });
    setAddressModalShow(false);
  };

  const handleSnsChange = (e, item, index) => {
    let newList = snsList;
    item = { ...item, link: e.target.value };
    newList[index] = item;
    setSnsList(newList);
  };

  const changeProfileImage = (e) => {
    console.log("프로필파일");
    let reader = new FileReader();
    let imageFile = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        profileImageFile: imageFile,
        profileImage: reader.result,
      });
    };
    reader.readAsDataURL(imageFile);
  };

  const changeBusinessImage = (e) => {
    console.log("사업자등록증파일");
    let reader = new FileReader();
    let imageFile = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        businessNumImageFile: imageFile,
        businessNumImage: reader.result,
      });
    };
    reader.readAsDataURL(imageFile);
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
      // return false;
    }
    reload();
  }, []);

  const onSubmit = () => {
    if (!input.sectors || input.sectors == "") {
      alert("업종을 입력해주세요");
      return false;
    }
    if (!input.category || input.category == "") {
      alert("분류를 입력해주세요");
      return false;
    }
    if (!input.name || input.name == "") {
      alert("상호를 입력해주세요");
      return false;
    }
    if (!input.confirm || input.confirm == "") {
      alert("승인여부를 입력해주세요");
      return false;
    }
    if (!input.userId || input.userId == "") {
      alert("담당자 유저번호를 입력해주세요");
      return false;
    }
    if (!input.address || input.address == "") {
      alert("주소를 입력해주세요");
      return false;
    }
    if (!input.businessNum || input.businessNum == "") {
      alert("사업자번호를 입력해주세요");
      return false;
    }
    if (!input.phone || input.phone == "") {
      alert("휴대폰번호를 입력해주세요");
      return false;
    }
    if (!input.email || input.email == "") {
      alert("이메일을 입력해주세요");
      return false;
    }
    // if (!input.homepage || input.homepage == "") {
    //   alert("홈페이지 주소를 입력해주세요");
    //   return false;
    // }
    if (!input.subtitle || input.subtitle == "") {
      alert("제목을 입력해주세요");
      return false;
    }
    if (!input.tags || input.tags == "") {
      alert("태그를 입력해주세요");
      return false;
    }
    if (!editorText) {
      alert("내용을 입력해주세요");
      return false;
    }
    if (!input.profileImage || input.profileImage == "") {
      alert("대표이미지를 입력해주세요");
      return false;
    }

    const bodyFormData = new FormData();
    bodyFormData.set("sectors", input.sectors);
    bodyFormData.set("category", input.category);
    bodyFormData.set("name", input.name);
    bodyFormData.set("confirm", input.confirm);
    bodyFormData.set("userId", input.userId);
    bodyFormData.set("address", input.address);

    fetchData(`admin/brandXy/${input.address}`).then(function (response) {
      console.log(response);
      if (response && response.data) {
        bodyFormData.set("latitude", response.data.lat);
        bodyFormData.set("longitude", response.data.lng);
      } else {
        bodyFormData.set("latitude", null);
        bodyFormData.set("longitude", null);
      }

      bodyFormData.set("businessNum", input.businessNum);
      bodyFormData.set("phone", input.phone);
      bodyFormData.set("email", input.email);
      if (input.homepage != undefined && input.homepage != null) {
        bodyFormData.set("homepage", input.homepage);
      }
      bodyFormData.set("subtitle", input.subtitle);
      bodyFormData.set("tags", input.tags);
      bodyFormData.set("contents", editorText);
      if (
        input.profileImageFile != undefined &&
        input.profileImageFile != null
      ) {
        bodyFormData.set("profileImageFile", input.profileImageFile);
      }

      if (
        input.businessNumImageFile != undefined &&
        input.businessNumImageFile != null
      ) {
        bodyFormData.set("businessNumImageFile", input.businessNumImageFile);
      }

      console.log(bodyFormData);

      editFormData(`admin/brand/${input.id}`, bodyFormData).then(function (
        response
      ) {
        console.log(response);
        if (response.result) {
          //sns의 경우 추가/등록/삭제가 들어가야함 그냥 수정만은 아니고..
          // if(data.BrandSns.length>0 && data.BrandSns!==snsList){
          //   // data.BrandSns.map();
          //   //반복문 돌려서 내용 다른경우 업데이트, 삭제 또는 추가
          //   //아니면 리스트 전체 보내고 일괄 삭제 후 새로 업데이트?
          //   consolelog('sns변동사항있음');
          //   data.BrandSns
          //   editFormData(`admin/brand/${input.id}`, bodyFormData).then(function (
          //     response
          //   ) {

          //   });
          // }

          if (fileList.length > 0) {
            const newList = fileList.map((item) => {
              return item.files;
            });
            const bodyFormData = new FormData();
            bodyFormData.set("type", "brand");
            bodyFormData.set("contentsId", input.id);

            for (var i = 0; i < newList.length; i++) {
              bodyFormData.append("files", newList[i]);
            }
            addFormData(`admin/gallery`, bodyFormData).then(function (
              response
            ) {
              if (response.result) {
                console.log(response.data);
              }
            });
          }

          alert("브랜드 정보 수정이 완료되었습니다.");
          history.push(`/admin/brandList/1`);
        } else {
          alert(response);
        }
      });
    });
  };
  const onRemove = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      deleteData(`admin/recomm`, input.id).then(function (response) {
        if (response.result) {
          alert("삭제성공");
          history.push(`/admin/recommList/1`);
        } else {
          alert("삭제실패");
          return false;
        }
      });
    }
  };

  // const onRemove = () => {
  //   if (window.confirm("삭제하시겠습니까?")) {
  //     deleteData(`admin/brand`, input.userId).then(function (response) {
  //       if (response.result) {
  //         alert("삭제성공");
  //         history.push(`/admin/brandList/1`);
  //       } else {
  //         alert("삭제실패");
  //         return false;
  //       }
  //     });
  //   }
  // };

  /**
   *
   * 서머노트 관련 함수
   */
  const onChange = (content) => {
    setEditorText(content);
  };

  const onImageUpload = (images, insertImage) => {
    console.log("onImageUpload", images);
    const bodyFormData = new FormData();
    for (let i = 0; i < images.length; i++) {
      bodyFormData.set("files", images[i]);
      addFileUpload(`admin/image-insert`, bodyFormData).then(function (
        response
      ) {
        console.log(response);
        insertImage(response.data.filePath);
      });
    }
  };

  const insertSns = (value) => {
    if (value === "youtube" && (youtube === undefined || youtube === "")) {
      alert("Youtube 링크주소를 입력해주세요.");
      return false;
    }

    if (value === "facebook" && (facebook === undefined || facebook === "")) {
      alert("Facebook 링크주소를 입력해주세요.");
      return false;
    }

    if (
      value === "instagram" &&
      (instagram === undefined || instagram === "")
    ) {
      alert("Instagram 링크주소를 입력해주세요.");
      return false;
    }

    const bodyFormData = new FormData();

    bodyFormData.set("brandId", input.id);
    if (value === "youtube") {
      bodyFormData.set("type", "youtube");
      bodyFormData.set("link", youtube);
    } else if (value === "facebook") {
      bodyFormData.set("type", "facebook");
      bodyFormData.set("link", facebook);
    } else if (value === "instagram") {
      bodyFormData.set("type", "instagram");
      bodyFormData.set("link", instagram);
    }

    addFormData(`user/brandSns`, bodyFormData).then(function (response) {
      console.log(response);
      if (response.result) {
        alert("등록이 완료되었습니다.");
        reload();
      } else {
        alert(response);
      }
    });
  };

  const updateSns = (value) => {
    if (value === "youtube" && (youtube === undefined || youtube === "")) {
      alert("Youtube 링크주소를 입력해주세요.");
      return false;
    }

    if (value === "facebook" && (facebook === undefined || facebook === "")) {
      alert("Facebook 링크주소를 입력해주세요.");
      return false;
    }

    if (
      value === "instagram" &&
      (instagram === undefined || instagram === "")
    ) {
      alert("Instagram 링크주소를 입력해주세요.");
      return false;
    }

    const bodyFormData = new FormData();
    let typeId;

    bodyFormData.set("brandId", input.id);
    if (value === "youtube") {
      bodyFormData.set("type", "youtube");
      bodyFormData.set("link", youtube);
      typeId = youtubeId;
    } else if (value === "facebook") {
      bodyFormData.set("type", "facebook");
      bodyFormData.set("link", facebook);
      typeId = facebookId;
    } else if (value === "instagram") {
      bodyFormData.set("type", "instagram");
      bodyFormData.set("link", instagram);
      typeId = instagramId;
    }

    console.log("typeId", typeId);

    editFormData(`user/brandSns/${typeId}`, bodyFormData).then(function (
      response
    ) {
      console.log(response);
      if (response.result) {
        alert("수정이 완료되었습니다.");
        reload();
      } else {
        alert(response);
      }
    });
  };

  const deleteSns = (value) => {
    const bodyFormData = new FormData();

    if (value === "youtube") {
      bodyFormData.set("id", youtubeId);
    } else if (value === "facebook") {
      bodyFormData.set("id", facebookId);
    } else if (value === "instagram") {
      bodyFormData.set("id", instagramId);
    }

    if (window.confirm("삭제하시겠습니까?")) {
      deleteData(`user/brandSns`, bodyFormData.get("id")).then(function (
        response
      ) {
        if (response.result) {
          alert("삭제되었습니다.");
          window.location.reload();
        } else {
          alert("삭제실패");
        }
      });
    }
  };

  const onInit = (note) => {
    note.reset();
    const regex = /(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/i;
    if (data && data.contents) {
      console.log("tset", data.contents.match(regex));
      if (data.contents.match(regex) !== null) {
        note.replace(data && data.contents);
      } else {
        note.insertText(data && data.contents);
      }
    }
  };

  const changeGalleryImage = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      // const newList = fileList.concat(file);
      const newItem = {
        files: file,
        image: reader.result,
      };
      console.log(fileList.concat(newItem));
      setFileList(fileList.concat(newItem));
    };
    reader.readAsDataURL(file);
  };

  const deleteImage = (e) => {
    const newList = fileList.filter((item, index) => index != e);
    setFileList(newList);
  };

  const deteleSavedImage = (e) => {
    if (window.confirm("삭제하시겠습니까?")) {
      deleteData(`admin/gallery`, e).then(function (response) {
        console.log(response);
        if (response.result) {
          alert("삭제성공");
          reload();
          const newList = data.gallery.filter((item, index) => index != e);
          console.log(newList);
          setData({ ...data, gallery: newList });
        } else {
          alert("삭제실패");
        }
      });
    }
  };

  return (
    <div className="content">
      <Grid fluid>
        {data && (
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col md={7}>
              <Card
                title="브랜드 상세정보"
                content={
                  <>
                    <FormInputs
                      ncols={["col-md-3", "col-md-9"]}
                      properties={[
                        {
                          label: "번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "id",
                          disabled: true,
                          value: input.id || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "가입일",
                          type: "text",
                          bsClass: "form-control",
                          name: "createdAt",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.createdAt
                            ? Moment(input.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-3", "col-md-3", "col-md-4", "col-md-2"]}
                      properties={[
                        {
                          label: "업종",
                          type: "text",
                          bsClass: "form-control",
                          name: "sectors",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.sectors || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "분류",
                          type: "text",
                          bsClass: "form-control",
                          name: "category",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.category || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "상호",
                          type: "text",
                          bsClass: "form-control",
                          name: "name",
                          // disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.name || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "승인여부",
                          type: "text",
                          bsClass: "form-control",
                          name: "confirm",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.confirm || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-3", "col-md-5", "col-md-4"]}
                      properties={[
                        {
                          label: "담당자 유저번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "userId",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.userId || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "주소",
                          type: "address",
                          bsClass: "form-control",
                          name: "address",
                          // onChange: (e) => handleInputChange(e),
                          onChange: (e) => {
                            console.log("click");
                            setAddressModalShow(true);
                          },
                          // disabled: true,
                          value: input.address || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "사업자번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "businessNum",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.businessNum || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-3", "col-md-4", "col-md-5"]}
                      properties={[
                        {
                          label: "휴대폰번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "phone",
                          // disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.phone || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "이메일",
                          type: "text",
                          bsClass: "form-control",
                          name: "email",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.email || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "홈페이지",
                          type: "text",
                          bsClass: "form-control",
                          name: "homepage",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.homepage || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-5", "col-md-7"]}
                      properties={[
                        {
                          label: "제목",
                          type: "text",
                          bsClass: "form-control",
                          name: "subtitle",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.subtitle || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "태그",
                          type: "text",
                          bsClass: "form-control",
                          name: "tags",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.tags || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />

                    <Row>
                      <Col md={12}>
                        <FormGroup controlId="formControlsTextarea">
                          <ControlLabel>내용</ControlLabel>
                          <ReactSummernote
                            options={{
                              lang: "ko-KR",
                              height: 350,
                              dialogsInBody: true,
                              toolbar: [
                                ["style", ["style"]],
                                ["font", ["bold", "underline", "clear"]],
                                ["fontname", ["fontname"]],
                                ["para", ["ul", "ol", "paragraph"]],
                                ["table", ["table"]],
                                ["insert", ["link", "picture", "video"]],
                                ["view", ["fullscreen", "codeview"]],
                              ],
                            }}
                            value={editorText}
                            onChange={onChange}
                            onImageUpload={onImageUpload}
                            onInit={onInit}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button
                      bsStyle="info"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={onSubmit}
                    >
                      수정
                    </Button>
                    <Button
                      bsStyle="warning"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={onRemove}
                    >
                      삭제
                    </Button>
                    <Button
                      bsStyle="success"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={(e) => history.goBack()}
                    >
                      목록
                    </Button>
                    <div className="clearfix" />
                  </>
                }
              />
              <Card
                title="SNS 정보"
                content={
                  <div>
                    <div
                      style={{
                        fontSize: "15px",
                        color: "#0b0a0a",
                        marginBottom: "5px",
                      }}
                    >
                      Youtube
                    </div>
                    <Row>
                      <Col lg={9} xs={9} style={{ paddingTop: "0px" }}>
                        <input
                          // placeholder={props.placeholder}
                          name="youtube"
                          type="text"
                          className="form-control"
                          value={youtube || ""}
                          style={{ cursor: "initial" }}
                          onChange={(e) => setYoutube(e.target.value)}
                          id="youtube"
                        />
                      </Col>
                      <Col
                        lg={3}
                        xs={3}
                        style={{ paddingTop: "0px", paddingLeft: "0px" }}
                      >
                        {!youtubeYn && (
                          <Button
                            bsStyle="success"
                            variant="outlined"
                            style={{ borderWidth: "1px" }}
                            onClick={() => insertSns("youtube")}
                          >
                            등록
                          </Button>
                        )}
                        {youtubeYn && (
                          <div>
                            <Button
                              bsStyle="info"
                              variant="outlined"
                              style={{
                                borderWidth: "1px",
                                marginRight: "10px",
                              }}
                              onClick={() => updateSns("youtube")}
                            >
                              수정
                            </Button>
                            <Button
                              bsStyle="warning"
                              variant="outlined"
                              style={{ borderWidth: "1px" }}
                              onClick={() => deleteSns("youtube")}
                            >
                              삭제
                            </Button>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <div
                      style={{
                        fontSize: "15px",
                        color: "#0b0a0a",
                        marginBottom: "5px",
                        marginTop: "15px",
                      }}
                    >
                      Facebook
                    </div>
                    <Row>
                      <Col lg={9} xs={9} style={{ paddingTop: "0px" }}>
                        <input
                          // placeholder={props.placeholder}
                          name="facebook"
                          type="text"
                          className="form-control"
                          value={facebook || ""}
                          style={{ cursor: "initial" }}
                          onChange={(e) => setFacebook(e.target.value)}
                          id="facebook"
                        />
                      </Col>
                      <Col
                        lg={3}
                        xs={3}
                        style={{ paddingTop: "0px", paddingLeft: "0px" }}
                      >
                        {!facebookYn && (
                          <Button
                            bsStyle="success"
                            variant="outlined"
                            style={{ borderWidth: "1px" }}
                            onClick={() => insertSns("facebook")}
                          >
                            등록
                          </Button>
                        )}
                        {facebookYn && (
                          <div>
                            <Button
                              bsStyle="info"
                              variant="outlined"
                              style={{
                                borderWidth: "1px",
                                marginRight: "10px",
                              }}
                              onClick={() => updateSns("facebook")}
                            >
                              수정
                            </Button>
                            <Button
                              bsStyle="warning"
                              variant="outlined"
                              style={{ borderWidth: "1px" }}
                              onClick={() => deleteSns("facebook")}
                            >
                              삭제
                            </Button>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <div
                      style={{
                        fontSize: "15px",
                        color: "#0b0a0a",
                        marginBottom: "5px",
                        marginTop: "15px",
                      }}
                    >
                      Instagram
                    </div>
                    <Row>
                      <Col lg={9} xs={9} style={{ paddingTop: "0px" }}>
                        <input
                          // placeholder={props.placeholder}
                          name="instagram"
                          type="text"
                          className="form-control"
                          value={instagram || ""}
                          style={{ cursor: "initial" }}
                          onChange={(e) => setInstagram(e.target.value)}
                          id="instagram"
                        />
                      </Col>
                      <Col
                        lg={3}
                        xs={3}
                        style={{ paddingTop: "0px", paddingLeft: "0px" }}
                      >
                        {!instagramYn && (
                          <Button
                            bsStyle="success"
                            variant="outlined"
                            style={{ borderWidth: "1px" }}
                            onClick={() => insertSns("instagram")}
                          >
                            등록
                          </Button>
                        )}
                        {instagramYn && (
                          <div>
                            <Button
                              bsStyle="info"
                              variant="outlined"
                              style={{
                                borderWidth: "1px",
                                marginRight: "10px",
                              }}
                              onClick={() => updateSns("instagram")}
                            >
                              수정
                            </Button>
                            <Button
                              bsStyle="warning"
                              variant="outlined"
                              style={{ borderWidth: "1px" }}
                              onClick={() => deleteSns("instagram")}
                            >
                              삭제
                            </Button>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                }
              />
              <Card
                title="갤러리 이미지"
                content={
                  <>
                    <label
                      className="fileInputStyle"
                      htmlFor="file-input"
                      style={{
                        color: "rgba(0, 0, 0, 0.87)",
                        marginBottom: "15px",
                        fontSize: "14px",
                        display: "table",
                      }}
                    >
                      업로드
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      name="profileImage"
                      style={{ marginBottom: "10px", display: "none" }}
                      onChange={changeGalleryImage}
                    />
                    {data &&
                      data.gallery &&
                      data.gallery.length > 0 &&
                      data.gallery.map((item, index) => {
                        console.log(item);
                        return (
                          <i
                            className="listImage"
                            key={`saved${item.id}`}
                            style={{ position: "relative" }}
                          >
                            <img
                              style={{
                                borderRadius: "5px",
                                verticalAlign: "middle",
                                width: "175px",
                                height: "175px",
                                margin: "10px 10px",
                              }}
                              src={
                                item.image &&
                                item.image != "null" &&
                                item.image != "undefined"
                                  ? item.image
                                  : ""
                              }
                            />
                            <button
                              className="deleteImageButton"
                              onClick={(e) => deteleSavedImage(item.id)}
                            >
                              ×
                            </button>
                          </i>
                        );
                      })}

                    {fileList &&
                      fileList.map((item, index) => {
                        return (
                          <i
                            className="listImage"
                            key={index}
                            style={{ position: "relative" }}
                          >
                            <img
                              style={{
                                borderRadius: "5px",
                                verticalAlign: "middle",
                                width: "175px",
                                height: "175px",
                                margin: "10px 10px",
                              }}
                              src={
                                item.image &&
                                item.image != "null" &&
                                item.image != "undefined"
                                  ? item.image
                                  : ""
                              }
                            />
                            <button
                              className="deleteImageButton"
                              onClick={(e) => deleteImage(index)}
                            >
                              ×
                            </button>
                          </i>
                        );
                      })}
                  </>
                }
              />
            </Col>
            <Col md={3}>
              {input && (
                <>
                  <Card
                    title="대표 이미지"
                    content={
                      <>
                        <label
                          className="fileInputStyle"
                          htmlFor="fileInput3"
                          style={{
                            color: "rgba(0, 0, 0, 0.87)",
                            marginBottom: "15px",
                            fontSize: "14px",
                          }}
                        >
                          업로드
                        </label>
                        <input
                          id="fileInput3"
                          type="file"
                          name="profileImageFile"
                          style={{ marginBottom: "10px", display: "none" }}
                          onChange={changeProfileImage}
                          accept=".jpg, .jpeg, .png, .bmp"
                        />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            style={{
                              maxWidth: "90%",
                              maxHeight: "350px",
                              borderRadius: "5px",
                              verticalAlign: "middle",
                            }}
                            src={input.profileImage || ""}
                          />
                        </div>
                      </>
                    }
                  />
                  <Card
                    title="사업자등록증 이미지"
                    content={
                      <>
                        <label
                          className="fileInputStyle"
                          htmlFor="fileInput2"
                          style={{
                            color: "rgba(0, 0, 0, 0.87)",
                            marginBottom: "15px",
                            fontSize: "14px",
                          }}
                        >
                          업로드
                        </label>
                        <input
                          id="fileInput2"
                          type="file"
                          name="businessNumImageFile"
                          style={{ marginBottom: "10px", display: "none" }}
                          onChange={changeBusinessImage}
                          accept=".jpg, .jpeg, .png, .bmp"
                        />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            style={{
                              maxWidth: "90%",
                              maxHeight: "350px",
                              borderRadius: "5px",
                              verticalAlign: "middle",
                            }}
                            src={input.businessNumImage || ""}
                          />
                        </div>
                      </>
                    }
                  />
                </>
              )}
            </Col>
          </Row>
        )}
      </Grid>

      <Modal
        show={addressModalShow}
        onHide={() => setAddressModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered="true"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>
        <Modal.Body>
          <DaumPostcode onComplete={handleComplete} key="daum" />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default BrandDetail;
