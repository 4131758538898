import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { useHistory } from "react-router-dom";
// import { UserCard } from "views/users/node_modules/components/UserCard/UserCard.jsx.js";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  fetchData,
  editFormData,
  addFileUpload,
  deleteData,
} from "modules/api.js";
import { useStyles } from "./style.jsx";
import Moment from "moment";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-ko-KR";

function ItemDetail(props) {
  const [input, setInput] = useState();
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState();
  const [editorText, setEditorText] = useState("");
  const [galleryList, setGalleryList] = useState();
  const [snsList, setSnsList] = useState([]);
  const [changeSns, setChangeSns] = useState();
  const reload = () => {
    (async function anyNameFunction() {
      console.log(props.match.params);
      const result = await fetchData(`admin/item/${props.match.params.value}`);
      console.log(result);
      if (!result.result) {
        alert(result.msg);
        history.goBack();
      } else {
        setInput(result.data);
        setData(result.data);
        setEditorText(result.data.contents);
        setGalleryList(result.data.gallery);
      }
    })();
  };

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const changeProfileImage = (e) => {
    console.log("프로필파일");
    let reader = new FileReader();
    let imageFile = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        imageFile: imageFile,
        image: reader.result,
      });
    };
    reader.readAsDataURL(imageFile);
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
      // return false;
    }
    reload();
  }, []);

  const onSubmit = () => {
    if (!input.confirm || input.confirm == "") {
      alert("승인여부를 입력해주세요");
      return false;
    }
    if (!input.sectors || input.sectors == "") {
      alert("분류를 입력해주세요");
      return false;
    }
    if (!input.name || input.name == "") {
      alert("품명을 입력해주세요");
      return false;
    }
    if (!input.price || input.price == "") {
      alert("가격을 입력해주세요");
      return false;
    }
    if (!input.userId || input.userId == "") {
      alert("담당자 유저번호를 입력해주세요");
      return false;
    }
    if (!input.phone || input.phone == "") {
      alert("휴대폰번호를 입력해주세요");
      return false;
    }
    if (!input.email || input.email == "") {
      alert("이메일을 입력해주세요");
      return false;
    }
    // if (!input.homepage || input.homepage == "") {
    //   alert("홈페이지 주소를 입력해주세요");
    //   return false;
    // }
    if (!input.tags || input.tags == "") {
      alert("태그를 입력해주세요");
      return false;
    }
    if (!editorText) {
      alert("내용을 입력해주세요");
      return false;
    }
    if (!input.image || input.image == "") {
      alert("대표이미지를 입력해주세요");
      return false;
    }

    const bodyFormData = new FormData();
    bodyFormData.set("confirm", input.confirm);
    bodyFormData.set("sectors", input.sectors);
    bodyFormData.set("name", input.name);
    bodyFormData.set("price", input.price);
    bodyFormData.set("userId", input.userId);
    bodyFormData.set("phone", input.phone);
    bodyFormData.set("email", input.email);
    if (input.homepage != undefined && input.homepage != null) {
      bodyFormData.set("homepage", input.homepage);
    }
    bodyFormData.set("tags", input.tags);
    bodyFormData.set("contents", editorText);
    if (input.imageFile != undefined && input.imageFile != null) {
      bodyFormData.set("files", input.imageFile);
    }

    editFormData(`admin/item/${input.id}`, bodyFormData).then(function (
      response
    ) {
      console.log(response);
      if (response.result) {
        alert("상품 정보 수정이 완료되었습니다.");
        history.push(`/admin/itemList/1`);
      } else {
        alert(response);
      }
    });
  };

  // const onRemove = () => {
  //   if (window.confirm("삭제하시겠습니까?")) {
  //     deleteData(`admin/brand`, input.userId).then(function (response) {
  //       if (response.result) {
  //         alert("삭제성공");
  //         history.push(`/admin/brandList/1`);
  //       } else {
  //         alert("삭제실패");
  //         return false;
  //       }
  //     });
  //   }
  // };

  /**
   *
   * 서머노트 관련 함수
   */
  const onChange = (content) => {
    setEditorText(content);
  };

  const onImageUpload = (images, insertImage) => {
    console.log("onImageUpload", images);
    const bodyFormData = new FormData();
    for (let i = 0; i < images.length; i++) {
      bodyFormData.set("files", images[i]);
      addFileUpload(`admin/image-insert`, bodyFormData).then(function (
        response
      ) {
        console.log(response.data.filePath);
        insertImage(response.data.filePath);
      });
    }
  };

  const onInit = (note) => {
    note.reset();
    const regex = /(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/i;
    if (data && data.contents) {
      console.log("tset", data.contents.match(regex));
      if (data.contents.match(regex) !== null) {
        note.replace(data && data.contents);
      } else {
        note.insertText(data && data.contents);
      }
    }
  };

  return (
    <div className="content">
      <Grid fluid>
        {data && (
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col md={7}>
              <Card
                title="상품 상세정보"
                content={
                  <form onSubmit={onSubmit}>
                    <FormInputs
                      ncols={["col-md-3", "col-md-7", "col-md-2"]}
                      properties={[
                        {
                          label: "번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "id",
                          disabled: true,
                          value: input.id || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "가입일",
                          type: "text",
                          bsClass: "form-control",
                          name: "createdAt",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.createdAt
                            ? Moment(input.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "승인여부",
                          type: "text",
                          bsClass: "form-control",
                          name: "confirm",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.confirm || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-3", "col-md-6", "col-md-3"]}
                      properties={[
                        {
                          label: "분류",
                          type: "text",
                          bsClass: "form-control",
                          name: "sectors",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.sectors || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "품명",
                          type: "text",
                          bsClass: "form-control",
                          name: "name",
                          // disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.name || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "가격",
                          type: "text",
                          bsClass: "form-control",
                          name: "price",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.price || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-3", "col-md-4", "col-md-5"]}
                      properties={[
                        {
                          label: "담당자 유저번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "userId",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.userId || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "휴대폰번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "phone",
                          // disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.phone || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "이메일",
                          type: "text",
                          bsClass: "form-control",
                          name: "email",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.email || "",
                          style: { cursor: "initial" },
                        },
                        // {
                        //   label: "주소",
                        //   type: "text",
                        //   bsClass: "form-control",
                        //   name: "address",
                        //   onChange: (e) => handleInputChange(e),
                        //   // disabled: true,
                        //   value: input.address || "",
                        //   style: { cursor: "initial" },
                        // },
                        // {
                        //   label: "사업자번호",
                        //   type: "text",
                        //   bsClass: "form-control",
                        //   name: "businessNum",
                        //   onChange: (e) => handleInputChange(e),
                        //   // disabled: true,
                        //   value: input.businessNum || "",
                        //   style: { cursor: "initial" },
                        // },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "홈페이지",
                          type: "text",
                          bsClass: "form-control",
                          name: "homepage",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.homepage || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        // {
                        //   label: "제목",
                        //   type: "text",
                        //   bsClass: "form-control",
                        //   name: "subtitle",
                        //   onChange: (e) => handleInputChange(e),
                        //   // disabled: true,
                        //   value: input.subtitle || "",
                        //   style: { cursor: "initial" },
                        // },
                        {
                          label: "태그",
                          type: "text",
                          bsClass: "form-control",
                          name: "tags",
                          onChange: (e) => handleInputChange(e),
                          // disabled: true,
                          value: input.tags || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />

                    <Row>
                      <Col md={12}>
                        <FormGroup controlId="formControlsTextarea">
                          <ControlLabel>내용</ControlLabel>
                          <ReactSummernote
                            options={{
                              lang: "ko-KR",
                              height: 350,
                              dialogsInBody: true,
                              toolbar: [
                                ["style", ["style"]],
                                ["font", ["bold", "underline", "clear"]],
                                ["fontname", ["fontname"]],
                                ["para", ["ul", "ol", "paragraph"]],
                                ["table", ["table"]],
                                ["insert", ["link", "picture", "video"]],
                                ["view", ["fullscreen", "codeview"]],
                              ],
                            }}
                            value={editorText}
                            onChange={onChange}
                            onImageUpload={onImageUpload}
                            onInit={onInit}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button
                      bsStyle="info"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={onSubmit}
                    >
                      수정
                    </Button>
                    <Button
                      bsStyle="success"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={(e) => history.goBack()}
                    >
                      목록
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
              {/* {snsList.length > 0 && (
                <Card
                  title="SNS 정보"
                  content={
                    <form onSubmit={onSubmit}>
                      {snsList.map((item, index) => {
                        return (
                          <FormInputs
                            key={index}
                            ncols={["col-md-12"]}
                            properties={[
                              {
                                label: item.type,
                                type: "text",
                                bsClass: "form-control",
                                name: item.type,
                                onChange: (e) => {
                                  setChangeSns(item);
                                  handleSnsChange(e, item, index);
                                },
                                // disabled: true,
                                value: item.link || "",
                                style: { cursor: "initial" },
                              },
                            ]}
                          />
                        );
                      })}
                      <div className="clearfix" />
                    </form>
                  }
                />
              )} */}
            </Col>
            <Col md={3}>
              {input && (
                <>
                  <Card
                    title="대표 이미지"
                    content={
                      <>
                        <label
                          className="fileInputStyle"
                          htmlFor="fileInput3"
                          style={{
                            color: "rgba(0, 0, 0, 0.87)",
                            marginBottom: "15px",
                            fontSize: "14px",
                          }}
                        >
                          업로드
                        </label>
                        <input
                          id="fileInput3"
                          type="file"
                          name="imageFile"
                          style={{ marginBottom: "10px", display: "none" }}
                          onChange={changeProfileImage}
                          accept=".jpg, .jpeg, .png, .bmp"
                        />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            style={{
                              maxWidth: "90%",
                              maxHeight: "350px",
                              borderRadius: "5px",
                              verticalAlign: "middle",
                            }}
                            src={input.image || ""}
                          />
                        </div>
                      </>
                    }
                  />
                </>
              )}
            </Col>
          </Row>
        )}
      </Grid>
    </div>
  );
}

export default ItemDetail;
