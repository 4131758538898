import React, { useState, useEffect } from "react";
import { fetchData } from "modules/api.js";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import Pagination from "@material-ui/lab/Pagination";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import {
	style,
	buttonDivStyle,
	buttonStyle1,
	BootstrapInput,
	useStyles,
} from "./style.jsx";

function BrandList(props) {
	const userThArray = [
		"번호",
		"이름",
		"분류",
		"주소",
		"휴대폰번호",
		"등록일",
		"승인여부",
	];
	const classes = useStyles();
	const [dataList, setDataList] = useState();
	const [total, setTotal] = useState(1);
	const history = useHistory();
	const [searchData, setSearchData] = useState({
		searchWord: "",
		status: "",
	});
	const [page, setPage] = useState(1);
	const [totalItem, setTotalItem] = useState();

	const reload = (value) => {
		if (value === undefined || value === null) {
			value = page;
		}

		(async function anyNameFunction() {
			const result = await fetchData(
				// `admin/brandList?page=${value}`
				`admin/brandList?page=${value}&searchWord=${searchData.searchWord}&searchType=${searchData.searchType}`
			);
			console.log(result);
			setTotalItem(result.data.count);
			setTotal(Math.ceil(result.data.count / 10));
			setDataList(result.data.rows);
		})();
	};

	useEffect(() => {
		const auth = localStorage.getItem("auth") || "";
		if (auth === "" || auth === undefined || auth === null) {
			history.push("/login");
		}
		setPage(Number(props.match.params.value));
		reload(Number(props.match.params.value));
	}, []);

	const pageChange = (event, value) => {
		setPage(value);
		reload(value);
		history.push(`/admin/userList/${value}`);
	};

	const searchChange = (event) => {
		if (window.event.keyCode === 13) {
			reload(1);
		}
		setSearchData({
			...searchData,
			[event.target.name]: event.target.value,
		});
	};

	const onSearch = () => {
		setPage(1);
		reload(1);
	};

	return (
		<div className="content">
			<Grid fluid>
				<Row>
					<Col md={12}>
						<Card
							title="브랜드 관리"
							ctTableFullWidth
							ctTableResponsive
							content={
								<div>
									<div style={buttonDivStyle}>
										<FormControl className={classes.margin}>
											<NativeSelect
												id="demo-customized-select-native"
												value={searchData.searchType || ""}
												onChange={(e) => searchChange(e)}
												input={<BootstrapInput />}
												name="searchType"
												style={{ font: "initial" }}
											>
												<option value="0">전체</option>
												<option value="1">이름</option>
												<option value="2">휴대폰번호</option>
											</NativeSelect>
										</FormControl>
										<FormControl className={classes.margin}>
											<BootstrapInput
												id="demo-customized-textbox"
												name="searchWord"
												onChange={(e) => searchChange(e)}
												value={searchData.searchWord || ""}
												onKeyUp={(e) => searchChange(e)}
											/>
										</FormControl>
										{/*
                    <FormControl className={classes.margin}>
                      <NativeSelect
                        id="demo-customized-select-native"
                        // value={searchData.type}
                        onChange={(e) => searchChange(e)}
                        input={<BootstrapInput />}
                        name="status"
                        style={{ font: "initial" }}
                      >
                        <>
                          <option value="all">전체</option>
                          <option value="join" key="join">
                            가입
                          </option>
                          <option value="leave" key="leave">
                            탈퇴
                          </option>
                        </>
                      </NativeSelect>
                    </FormControl>*/}
										<Button
											variant="outlined"
											style={buttonStyle1}
											onClick={(e) => onSearch(e)}
										>
											검색
										</Button>
									</div>
									<Table striped hover>
										<thead>
											<tr>
												{userThArray.map((prop, key) => {
													return <th key={key}>{prop}</th>;
												})}
											</tr>
										</thead>
										<tbody>
											{dataList && dataList.length > 0 ? (
												dataList.map((data, index) => {
													// data.no = totalItem - (page - 1) * 20 - index;
													// console.log(dataList.length);
													return (
														<tr
															key={index}
															onClick={(e) =>
																history.push(`/admin/brand/${data.id}`)
															}
														>
															<td>{data.id}</td>
															<td>{data.name}</td>
															<td>{data.category}</td>
															<td>{data.address}</td>
															<td>{data.phone}</td>
															<td>
																{(data.createdAt &&
																	Moment(data.createdAt).format(
																		"YYYY-MM-DD HH:mm:ss"
																	)) ||
																	""}
															</td>
															<td>{data.confirm}</td>
														</tr>
													);
												})
											) : (
												<tr>
													<td colSpan="7" style={{ textAlign: "center" }}>
														검색결과가 없습니다
													</td>
												</tr>
											)}
										</tbody>
									</Table>
								</div>
							}
						/>
						<Pagination
							count={total}
							page={page}
							size="large"
							onChange={pageChange}
							variant="outlined"
							shape="rounded"
							style={style}
						/>
					</Col>
				</Row>
			</Grid>
		</div>
	);
}

export default BrandList;
