import React, { Component } from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import NativeSelect from "@material-ui/core/NativeSelect";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { Tasks } from "components/Tasks/Tasks.jsx";
import Datepicker from "components/Datepicker/Datepicker.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { TagInput } from "components/TagInput/TagInput.jsx";

export const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const buttonStyle2 = {
  height: "34px",
  fontSize: "1.6rem",
  borderWidth: "1px",
  padding: "3px 15px",
  marginRight: "3px",
  marginLeft: "5px",
};

function FieldGroup({ label, onClick, ...props }) {
  const hourList = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
  ];
  const minuteList = ["00", "10", "20", "30", "40", "50"];
  // const [selectTime, setSelectTime] = useState({
  //   hour: "",
  //   minute: "",
  // });

  const onClickUser = (param) => {
    if (props.type === "user") {
      window.location.href = `/admin/user/${param}`;
    } else {
      window.location.href = `/admin/celeb/${param}`;
    }
  };

  const onChangeTime = (type, e) => {
    console.log(type);
    console.log(e);
    // if (type === "hour") {
    //   setSelectTime({
    //     ...selectTime,
    //     hour: e.target.value,
    //   });
    // } else {
    //   setSelectTime({
    //     ...selectTime,
    //     minute: e.target.value,
    //   });
    // }
    props.onChange(e);
  };

  return (
    <FormGroup>
      {props.type !== "select" &&
        props.type !== "selectDisplay" &&
        props.type !== "selectCategory" &&
        props.type !== "list" &&
        props.type !== "tags" &&
        props.type !== "address" &&
        props.type !== "searchAddress" &&
        props.type !== "user" &&
        props.type !== "time" &&
        props.type !== "searchId" &&
        props.type !== "calender" && (
          <>
            <ControlLabel>{label}</ControlLabel>
            <FormControl {...props} />
          </>
        )}

      {props.type == "searchId" && (
        <div>
          <ControlLabel>{label}</ControlLabel>
          <Row>
            <Col lg={12} xs={12} style={{ paddingTop: "0px" }}>
              <div style={{ display: "flex" }}>
                <input
                  placeholder={props.placeholder}
                  name={props.name}
                  type="text"
                  className="form-control"
                  value={props.value}
                  style={{ cursor: "initial" }}
                  id={props.id}
                  onChange={(e) => props.onChange(e)}
                  // disabled
                />
                <Button
                  variant="outlined"
                  style={{ borderWidth: "1px" }}
                  onClick={(e) => props.onSearchId()}
                >
                  확인
                </Button>
              </div>
            </Col>

            {/* <Col
              lg={3}
              xs={3}
              style={{ paddingTop: "0px", paddingLeft: "0px" }}
            >
              <Button
                variant="outlined"
                style={{ borderWidth: "1px" }}
                onClick={(e) => props.onSearchId()}
              >
                검색
              </Button>
            </Col> */}
          </Row>
        </div>
      )}

      {props.type === "address" && (
        <div>
          <ControlLabel>{label}</ControlLabel>
          <FormControl
            onClick={(e) => {
              console.log(props);
              props.onChange(e);
            }}
            {...props}
          />
        </div>
      )}

      {props.type === "searchAddress" && (
        <div>
          <ControlLabel>{label}</ControlLabel>
          <div style={{ display: "flex" }}>
            <FormControl
              label={props.label}
              bsClass={props.bsClass}
              name={props.name}
              onChange={props.onChange}
              value={props.value}
              style={props.style}
              // {...props}
            />
            {/* <button
              type="button"
              onClick={(e) => {
                props.buttonclick(e);
              }}
            >
              주소검색
            </button> */}

            <Button
              variant="outlined"
              style={buttonStyle2}
              type="button"
              onClick={(e) => {
                props.buttonclick(e);
              }}
            >
              주소검색
            </Button>
          </div>
        </div>
      )}

      {props.type === "time" && (
        <div>
          <ControlLabel>{label}</ControlLabel>
          <div style={{ display: "flex" }} className="timeselect">
            <NativeSelect
              id="demo-customized-select-native"
              input={<BootstrapInput />}
              style={{ font: "initial", marginTop: "0px", width: "100%" }}
              value={props.hour}
              onChange={(e) => onChangeTime("hour", e)}
              name="hour"
              className={props.className ? props.className : ""}
            >
              {hourList.map((item, index) => {
                return (
                  <option key={`hour${index}`} value={item}>
                    {item}
                  </option>
                );
              })}
            </NativeSelect>
            <div
              style={{
                minWidth: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              시간
            </div>
            <NativeSelect
              id="demo-customized-select-native"
              input={<BootstrapInput />}
              style={{ font: "initial", marginTop: "0px", width: "100%" }}
              value={props.minute}
              onChange={(e) => onChangeTime("minute", e)}
              name="minute"
              className={props.className ? props.className : ""}
            >
              {minuteList.map((item, index) => {
                return (
                  <option key={`minute${index}`} value={item}>
                    {item}
                  </option>
                );
              })}
            </NativeSelect>
            <div
              style={{
                minWidth: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              분
            </div>
          </div>
        </div>
      )}

      {props.type === "tags" && (
        <div>
          <TagInput list={props.value} type="celeb" />
        </div>
      )}

      {props.type === "calender" && (
        <div className="hangCalender">
          <ControlLabel>{label}</ControlLabel>
          <Datepicker
            onSetDate={(e) => {
              console.log(e);
              console.log(props);
              props.onChange(e, props.name);
            }}
          />
        </div>
      )}

      {props.type === "list" && ( // dashboard highlight, job list
        <Tasks taskList={props.list} type={props.name} onClick={props.del} />
      )}

      {props.type === "select" && (
        <>
          <ControlLabel>{label}</ControlLabel>
          <NativeSelect
            id="demo-customized-select-native"
            input={<BootstrapInput />}
            style={{ font: "initial", marginTop: "0px", width: "100%" }}
            value={props.value || ""}
            onChange={props.onChange}
            name={props.name}
            className={props.className ? props.className : ""}
          >
            {props.option}
          </NativeSelect>
        </>
      )}
      {props.type === "selectDisplay" && (
        <>
          <ControlLabel>{label}</ControlLabel>
          <NativeSelect
            id="demo-customized-select-native"
            input={<BootstrapInput />}
            style={{ font: "initial", marginTop: "0px", width: "100%" }}
            value={props.value || ""}
            onChange={props.onChange}
            name={props.name}
            className={props.className ? props.className : ""}
            disabled={props.disabled}
          >
            {props.option}
          </NativeSelect>
        </>
      )}
    </FormGroup>
  );
}

export class FormInputs extends Component {
  render() {
    var row = [];
    for (var i = 0; i < this.props.ncols.length; i++) {
      row.push(
        <div key={i} className={this.props.ncols[i]}>
          <FieldGroup {...this.props.properties[i]} />
        </div>
      );
    }
    return <Row>{row}</Row>;
  }
}

export default FormInputs;
