import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { useHistory } from "react-router-dom";
// import { UserCard } from "views/users/node_modules/components/UserCard/UserCard.jsx.js";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  fetchData,
  editFormData,
  addFormData,
  addFileUpload,
  deleteData,
} from "modules/api.js";
import { useStyles } from "./style.jsx";
import Moment from "moment";

function PushDetail(props) {
  const detailType = {
    type: "all",
    contentsId: null,
    // speciesId: null,
    contents: null,
  };

  const [input, setInput] = useState(detailType);
  const history = useHistory();
  const [data, setData] = useState();

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
      // return false;
    }
  }, []);

  const onAdd = () => {
    if (input.type === "breed" && !input.contentsId) {
      alert("품종번호를 입력해주세요");
      return false;
    }
    if (input.type === "species" && !input.contentsId) {
      alert("종 번호를 입력해주세요");
      return false;
    }
    if (input.type === "brandRecomm" && !input.contentsId) {
      alert("브랜드 번호를 입력해주세요");
      return false;
    }
    if (input.type === "newBrand" && !input.contentsId) {
      alert("브랜드 번호를 입력해주세요");
      return false;
    }
    if (!input.contents || input.contents == "") {
      alert("내용을 입력해주세요");
      return false;
    }
    console.log(input);

    addFormData(`admin/push`, input).then(function (response) {
      console.log(response);
      if (response.result) {
        alert("PUSH 등록이 완료되었습니다.");
        history.push(`/admin/pushList/1`);
      } else {
        alert(response);
      }
    });
  };

  return (
    <div className="content">
      <Grid fluid>
        {/* {data && ( */}
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col md={8}>
            <Card
              title={"PUSH 등록"}
              content={
                <form>
                  {input.type !== "all" && (
                    <FormInputs
                      ncols={["col-md-4", "col-md-3"]}
                      properties={[
                        {
                          label: "알람 유형",
                          type: "select",
                          bsClass: "form-control",
                          name: "type",
                          className: "infoSelect",
                          onChange: (e) => handleInputChange(e),
                          value: input.type || "",
                          style: { cursor: "initial" },
                          option: (
                            <>
                              <option value="all" key="all" defaultValue={true}>
                                전체
                              </option>
                              <option value="species" key="species">
                                종 ex)강아지
                              </option>
                              <option value="breed" key="breed">
                                품종 ex)푸들
                              </option>
                              <option value="brandRecomm" key="brandRecomm">
                                인근 브랜드 추천
                              </option>
                              <option value="newBrand" key="newBrand">
                                인근 새 브랜드 알림
                              </option>
                            </>
                          ),
                        },
                        {
                          label:
                            input.type === "species"
                              ? "종번호"
                              : input.type === "breed"
                              ? "품종번호"
                              : input.type === "brandRecomm"
                              ? "브랜드번호"
                              : input.type === "newBrand"
                              ? "브랜드번호"
                              : "",
                          type: "text",
                          bsClass: "form-control",
                          name: "contentsId",
                          onChange: (e) => handleInputChange(e),
                          disabled: input.type === "all" ? true : false,
                          value: input.contentsId || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                  )}
                  {input.type === "all" && (
                    <FormInputs
                      ncols={["col-md-4"]}
                      properties={[
                        {
                          label: "알람 유형",
                          type: "select",
                          bsClass: "form-control",
                          name: "type",
                          className: "infoSelect",
                          onChange: (e) => handleInputChange(e),
                          value: input.type || "",
                          style: { cursor: "initial" },
                          option: (
                            <>
                              <option value="all" key="all" defaultValue={true}>
                                전체
                              </option>
                              <option value="species" key="species">
                                종 ex)강아지
                              </option>
                              <option value="breed" key="breed">
                                품종 ex)푸들
                              </option>
                              <option value="brandRecomm" key="brandRecomm">
                                인근 브랜드 추천
                              </option>
                              <option value="newBrand" key="newBrand">
                                인근 새 브랜드 알림
                              </option>
                            </>
                          ),
                        },
                      ]}
                    />
                  )}
                  <FormInputs
                    ncols={["col-md-12"]}
                    properties={[
                      {
                        label: "내용",
                        type: "text",
                        bsClass: "form-control",
                        name: "contents",
                        onChange: (e) => handleInputChange(e),
                        // disabled: true,
                        value: input.contents || "",
                        style: { cursor: "initial" },
                      },
                    ]}
                  />
                  <Button
                    bsStyle="info"
                    style={{ marginLeft: "1%" }}
                    pullRight
                    fill
                    onClick={(e) => onAdd(e)}
                  >
                    등록
                  </Button>
                  <div className="clearfix" />
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default PushDetail;
