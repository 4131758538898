import React, { useState, useEffect } from "react";
import { fetchData } from "modules/api.js";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import Pagination from "@material-ui/lab/Pagination";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import {
  style,
  buttonDivStyle,
  buttonStyle1,
  buttonStyle2,
  BootstrapInput,
  useStyles,
} from "./style.jsx";

function BreedList(props) {
  const thArray = [
    "번호",
    "종 분류",
    "국문명",
    "영문명",
    "등록일",
    // "등록된 펫 수",
    // "등록된 클립 수",
  ];

  const classes = useStyles();
  const [dataList, setDataList] = useState();
  const [total, setTotal] = useState(1);
  const history = useHistory();
  const [searchData, setSearchData] = useState({
    searchWord: "",
    status: "",
  });
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState();

  const reload = (value) => {
    if (value === undefined || value === null) {
      value = page;
    }

    (async function anyNameFunction() {
      const result = await fetchData(
        // `user/breedList`
        `admin/breedList?page=${value}&searchWord=${searchData.searchWord}`
      );
      console.log(result);
      setTotalItem(result.data.count);
      setTotal(Math.ceil(result.data.count / 10));
      setDataList(result.data.rows);
    })();
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
    }
    console.log("재로딩");
    setPage(Number(props.match.params.value));
    reload(Number(props.match.params.value));
  }, []);

  const pageChange = (event, value) => {
    setPage(value);
    reload(value);
    history.push(`/admin/userList/${value}`);
  };

  const searchChange = (event) => {
    if (window.event.keyCode === 13) {
      reload(1);
    }
    setSearchData({
      ...searchData,
      [event.target.name]: event.target.value,
    });
  };

  const onSearch = () => {
    setPage(1);
    reload(1);
  };

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="품종 관리"
              ctTableFullWidth
              ctTableResponsive
              content={
                <div>
                  <div style={buttonDivStyle}>
                    <FormControl className={classes.margin}>
                      <BootstrapInput
                        id="demo-customized-textbox"
                        name="searchWord"
                        onChange={(e) => searchChange(e)}
                        value={searchData.searchWord || ""}
                        onKeyUp={(e) => searchChange(e)}
                      />
                    </FormControl>
                    {/* <FormControl className={classes.margin}>
                      <NativeSelect
                        id="demo-customized-select-native"
                        // value={searchData.type}
                        onChange={(e) => searchChange(e)}
                        input={<BootstrapInput />}
                        name="type"
                        style={{ font: "initial" }}
                      >
                        <>
                          <option value="" defaultValue>
                            전체
                          </option>
                          {categoryList &&
                            categoryList.map((item, index) => {
                              return (
                                <option value={item.type} key={index}>
                                  {item.title}
                                </option>
                              );
                            })}
                        </>
                      </NativeSelect>
                    </FormControl> */}
                    <Button
                      variant="outlined"
                      style={buttonStyle1}
                      onClick={(e) => onSearch(e)}
                    >
                      검색
                    </Button>
                    <Button
                      variant="outlined"
                      style={buttonStyle2}
                      onClick={(e) => history.push(`/admin/breed/add`)}
                    >
                      등록
                    </Button>
                  </div>
                  <Table striped hover>
                    <thead>
                      <tr>
                        {thArray.map((prop, key) => {
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {dataList && dataList.length > 0 ? (
                        dataList.map((data, index) => {
                          // data.no = totalItem - (page - 1) * 20 - index;
                          return (
                            <tr
                              key={data.id}
                              onClick={(e) =>
                                history.push(`/admin/breed/${data.id}`)
                              }
                            >
                              <td>{data.id || ""}</td>
                              <td>{data.species && data.species.nameKr || ""}</td>
                              <td>{data.nameKr || ""}</td>
                              <td>{data.nameEn || ""}</td>
                              {/* <td>{data.petCount || ""}</td>
                              <td>{data.clipCount || ""}</td> */}
                              <td>
                                {(data.createdAt &&
                                  Moment(data.createdAt).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )) ||
                                  ""}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="5" style={{ textAlign: "center" }}>
                            검색결과가 없습니다
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              }
            />
            <Pagination
              count={total}
              page={page}
              size="large"
              onChange={pageChange}
              variant="outlined"
              shape="rounded"
              style={style}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default BreedList;
