import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { useHistory } from "react-router-dom";
// import { UserCard } from "views/users/node_modules/components/UserCard/UserCard.jsx.js";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  fetchData,
  editFormData,
  getData,
  addFormData,
  addData,
  deleteData,
} from "modules/api.js";
import { useStyles } from "./style.jsx";
import Moment from "moment";
import DaumPostcode from "react-daum-postcode";

function UserDetail(props) {
  const [input, setInput] = useState();
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState();
  const [addressModalShow, setAddressModalShow] = useState(false);

  const reload = () => {
    (async function anyNameFunction() {
      const result = await fetchData(`admin/user/${props.match.params.value}`);

      // if (result.data.deletedAt !== null) {
      //   result.data.status = "leave";
      // } else {
      //   result.data.status = "join";
      // }
      setInput(result.data);
      setData(result.data);
    })();
  };

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const changeProfileImage = (e) => {
    let reader = new FileReader();
    let imageFile = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        files: imageFile,
        profileImg: reader.result,
      });
    };
    reader.readAsDataURL(imageFile);
  };

  // const handleComplete = (postCodeData) => {
  //   //주소변경
  //   console.log(postCodeData);

  //   (async function anyNameFunction() {
  //     const res = await fetchData(
  //       `api/getPosition?address=${postCodeData.address}`
  //     );
  //     console.log(res);

  //     if (res.result && res.data) {
  //       setInput({
  //         ...input,
  //         address: postCodeData.address,
  //         latitude: res.data.latitude,
  //         longitude: res.data.longitude,
  //       });
  //     } else {
  //       setInput({
  //         ...input,
  //         address: postCodeData.address,
  //       });
  //     }
  //   })();

  //   setAddressModalShow(false);
  // };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
      // return false;
    }
    reload();
  }, []);

  const onSubmit = () => {
    if (!input.nickname || input.nickname === "") {
      alert("닉네임을 입력해주세요");
      return false;
    }

    const bodyFormData = new FormData();
    bodyFormData.set("id", input.id);
    bodyFormData.set("nickname", input.nickname);

    if (input.profileImg != undefined && input.profileImg != null) {
      bodyFormData.set("profileImg", input.profileImg);
      bodyFormData.set("files", input.files);
    }

    editFormData(`admin/user/${input.id}`, bodyFormData).then(function (
      response
    ) {
      console.log(response);
      if (response.result) {
        alert("회원정보 수정이 완료되었습니다.");
        history.push(`/admin/userList/1`);
      } else {
        alert(response);
      }
    });
  };

  const onRemove = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      deleteData(`admin/user`, input.id).then(function (response) {
        if (response.result) {
          alert("삭제성공");
          history.push(`/admin/userList/1`);
        } else {
          alert("삭제실패");
          return false;
        }
      });
    }
  };

  return (
    <div className="content">
      <Grid fluid>
        {data && (
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col md={7}>
              <Card
                title="회원 상세정보"
                content={
                  <form onSubmit={onSubmit}>
                    <FormInputs
                      ncols={["col-md-3", "col-md-9"]}
                      properties={[
                        {
                          label: "번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "id",
                          disabled: true,
                          value: input.id || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "가입일",
                          type: "text",
                          bsClass: "form-control",
                          name: "createdAt",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.createdAt
                            ? Moment(input.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "",
                          style: { cursor: "initial" },
                        },
                        // {
                        //   label: "탈퇴일",
                        //   type: "text",
                        //   bsClass: "form-control",
                        //   name: "deletedAt",
                        //   disabled: true,
                        //   onChange: (e) => handleInputChange(e),
                        //   value: input.deletedAt
                        //     ? Moment(input.deletedAt).format(
                        //         "YYYY-MM-DD HH:mm:ss"
                        //       )
                        //     : "",
                        //   style: { cursor: "initial" },
                        // },
                        // {
                        //   label: "가입상태 변경",
                        //   type: "select",
                        //   bsClass: "form-control",
                        //   name: "status",
                        //   className: "infoSelect",
                        //   onChange: (e) => handleInputChange(e),
                        //   value: input.status || "",
                        //   style: { cursor: "initial" },
                        //   option: (
                        //     <>
                        //       <option value="join" key="join">
                        //         가입
                        //       </option>
                        //       <option value="leave" key="leave">
                        //         탈퇴
                        //       </option>
                        //     </>
                        //   ),
                        // },
                      ]}
                    />

                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        {
                          label: "이름",
                          type: "text",
                          bsClass: "form-control",
                          name: "name",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value:
                            input.name &&
                            input.name != "undefined" &&
                            input.name != "null"
                              ? input.name
                              : "",
                          style: { cursor: "initial" },
                        },

                        {
                          label: "휴대폰번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "phone",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.phone || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "닉네임",
                          type: "text",
                          bsClass: "form-control",
                          name: "nickname",
                          // disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.nickname || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <Button
                      bsStyle="info"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={onSubmit}
                    >
                      수정
                    </Button>
                    <Button
                      bsStyle="warning"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={onRemove}
                    >
                      삭제
                    </Button>
                    <Button
                      bsStyle="success"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={(e) => history.goBack()}
                    >
                      목록
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
            <Col md={3}>
              {input && (
                <Card
                  title="프로필 이미지"
                  content={
                    <>
                      <label
                        className="fileInputStyle"
                        htmlFor="fileInput"
                        style={{
                          color: "rgba(0, 0, 0, 0.87)",
                          marginBottom: "15px",
                          fontSize: "14px",
                        }}
                      >
                        업로드
                      </label>
                      <input
                        id="fileInput"
                        type="file"
                        name="files"
                        style={{ marginBottom: "10px", display: "none" }}
                        onChange={changeProfileImage}
                        accept=".jpg, .jpeg, .png, .bmp"
                      />
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          style={{
                            maxWidth: "90%",
                            maxHeight: "350px",
                            borderRadius: "5px",
                            verticalAlign: "middle",
                          }}
                          src={input.profileImg || ""}
                        />
                      </div>
                    </>
                  }
                />
              )}
            </Col>
          </Row>
        )}
      </Grid>

      {/* <Modal
        show={addressModalShow}
        onHide={() => setAddressModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered="true"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>
        <Modal.Body>
          <DaumPostcode onComplete={handleComplete} key="daum" />
        </Modal.Body>
      </Modal> */}
    </div>
  );
}

export default UserDetail;
