import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { useHistory } from "react-router-dom";
// import { UserCard } from "views/users/node_modules/components/UserCard/UserCard.jsx.js";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  fetchData,
  editFormData,
  addFormData,
  addFileUpload,
  deleteData,
} from "modules/api.js";
import { useStyles } from "./style.jsx";
import Moment from "moment";

function RecommDetail(props) {
  const detailType = {
    type: "column",
    contentsId: null,
    title: null,
    tags: null,
    image: null,
  };

  const [input, setInput] = useState(detailType);
  const history = useHistory();
  const [data, setData] = useState();

  const reload = () => {
    if (props.match.params.value !== "add") {
      (async function anyNameFunction() {
        console.log(props.match.params);
        const result = await fetchData(
          `user/recomm/${props.match.params.value}`
        );
        console.log(result);
        if (!result.result) {
          alert(result.msg);
          history.goBack();
        } else {
          setInput(result.data);
          setData(result.data);
        }
      })();
    }
  };

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const changeProfileImage = (e) => {
    console.log("프로필파일");
    let reader = new FileReader();
    let imageFile = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        imageFile: imageFile,
        image: reader.result,
      });
    };
    reader.readAsDataURL(imageFile);
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
      // return false;
    }
    reload(Number(props.match.params.value));
  }, []);

  const onAdd = () => {
    if (!input.type || input.type == "") {
      alert("게시글 유형을 입력해주세요");
      return false;
    }
    if (!input.contentsId || input.contentsId == "") {
      alert("원글 번호를 입력해주세요");
      return false;
    }
    if (!input.title || input.title == "") {
      alert("제목을 입력해주세요");
      return false;
    }
    if (!input.image || input.image == "") {
      alert("대표이미지를 입력해주세요");
      return false;
    }

    const bodyFormData = new FormData();
    bodyFormData.set("type", input.type);
    bodyFormData.set("contentsId", input.contentsId);
    bodyFormData.set("title", input.title);
    bodyFormData.set("tags", input.tags);
    bodyFormData.set("files", input.imageFile);

    addFormData(`admin/recomm`, bodyFormData).then(function (response) {
      console.log(response);
      if (response.result) {
        alert("추천글 등록이 완료되었습니다.");
        history.push(`/admin/recommList/1`);
      } else {
        alert(response);
      }
    });
  };

  const onSubmit = () => {
    if (!input.type || input.type == "") {
      alert("게시글 유형을 입력해주세요");
      return false;
    }
    if (!input.contentsId || input.contentsId == "") {
      alert("원글 번호를 입력해주세요");
      return false;
    }
    if (!input.title || input.title == "") {
      alert("제목을 입력해주세요");
      return false;
    }
    if (!input.image || input.image == "") {
      alert("대표이미지를 입력해주세요");
      return false;
    }

    const bodyFormData = new FormData();
    bodyFormData.set("type", input.type);
    bodyFormData.set("contentsId", input.contentsId);
    bodyFormData.set("title", input.title);
    bodyFormData.set("tags", input.tags);
    if (input.imageFile != undefined && input.imageFile != null) {
      bodyFormData.set("files", input.imageFile);
    }

    editFormData(`admin/recomm/${input.id}`, bodyFormData).then(function (
      response
    ) {
      console.log(response);
      if (response.result) {
        alert("상품 정보 수정이 완료되었습니다.");
        history.push(`/admin/recommList/1`);
      } else {
        alert(response);
      }
    });
  };

  const onRemove = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      deleteData(`admin/recomm`, input.id).then(function (response) {
        if (response.result) {
          alert("삭제성공");
          history.push(`/admin/recommList/1`);
        } else {
          alert("삭제실패");
          return false;
        }
      });
    }
  };

  return (
    <div className="content">
      <Grid fluid>
        {/* {data && ( */}
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col md={7}>
            <Card
              title={
                (props.match.params.value !== "add" && "추천글 상세정보") ||
                (props.match.params.value === "add" && "추천글 등록")
              }
              content={
                <form onSubmit={onSubmit}>
                  {props.match.params.value !== "add" && (
                    <FormInputs
                      ncols={["col-md-4", "col-md-8"]}
                      properties={[
                        {
                          label: "번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "id",
                          disabled: true,
                          value: input.id || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "등록일",
                          type: "text",
                          bsClass: "form-control",
                          name: "createdAt",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.createdAt
                            ? Moment(input.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                  )}

                  <FormInputs
                    ncols={["col-md-2", "col-md-2", "col-md-8"]}
                    properties={[
                      {
                        label: "게시글 유형",
                        type: "select",
                        bsClass: "form-control",
                        name: "type",
                        className: "infoSelect",
                        onChange: (e) => handleInputChange(e),
                        value: input.type || "",
                        style: { cursor: "initial" },
                        option: (
                          <>
                            <option
                              value="column"
                              key="column"
                              defaultValue={true}
                            >
                              칼럼
                            </option>
                            <option value="board" key="board">
                              게시글
                            </option>
                          </>
                        ),
                      },
                      {
                        label: "원글번호",
                        type: "text",
                        bsClass: "form-control",
                        name: "contentsId",
                        onChange: (e) => handleInputChange(e),
                        // disabled: true,
                        value: input.contentsId || "",
                        style: { cursor: "initial" },
                      },
                      {
                        label: "제목",
                        type: "text",
                        bsClass: "form-control",
                        name: "title",
                        onChange: (e) => handleInputChange(e),
                        // disabled: true,
                        value: input.title || "",
                        style: { cursor: "initial" },
                      },
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    properties={[
                      {
                        label: "태그",
                        type: "text",
                        bsClass: "form-control",
                        name: "tags",
                        onChange: (e) => handleInputChange(e),
                        // disabled: true,
                        value: input.tags || "",
                        style: { cursor: "initial" },
                      },
                    ]}
                  />
                  {props.match.params.value === "add" && (
                    <Button
                      bsStyle="info"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={(e) => onAdd(e)}
                    >
                      등록
                    </Button>
                  )}
                  {props.match.params.value !== "add" && (
                    <>
                      <Button
                        bsStyle="info"
                        style={{ marginLeft: "1%" }}
                        pullRight
                        fill
                        onClick={onSubmit}
                      >
                        수정
                      </Button>
                      <Button
                        bsStyle="warning"
                        style={{ marginLeft: "1%" }}
                        pullRight
                        fill
                        onClick={onRemove}
                      >
                        삭제
                      </Button>
                      <Button
                        bsStyle="success"
                        style={{ marginLeft: "1%" }}
                        pullRight
                        fill
                        onClick={(e) => history.goBack()}
                      >
                        목록
                      </Button>
                    </>
                  )}

                  <div className="clearfix" />
                </form>
              }
            />
          </Col>
          <Col md={3}>
            {input && (
              <>
                <Card
                  title="대표 이미지"
                  content={
                    <>
                      <label
                        className="fileInputStyle"
                        htmlFor="fileInput3"
                        style={{
                          color: "rgba(0, 0, 0, 0.87)",
                          marginBottom: "15px",
                          fontSize: "14px",
                        }}
                      >
                        업로드
                      </label>
                      <input
                        id="fileInput3"
                        type="file"
                        name="imageFile"
                        style={{ marginBottom: "10px", display: "none" }}
                        onChange={changeProfileImage}
                        accept=".jpg, .jpeg, .png, .bmp"
                      />
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          style={{
                            maxWidth: "90%",
                            maxHeight: "350px",
                            borderRadius: "5px",
                            verticalAlign: "middle",
                          }}
                          src={input.image || ""}
                        />
                      </div>
                    </>
                  }
                />
              </>
            )}
          </Col>
        </Row>
        {/* )} */}
      </Grid>
    </div>
  );
}

export default RecommDetail;
