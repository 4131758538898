import "date-fns";
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
// import search from "assets/images/icon/search.png";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import 'moment/locale/ko';
import koLocale from "date-fns/locale/ko";
// import { koKR } from '@material-ui/core/locale';

// const theme = createMuiTheme(
//   {
//     palette: {
//       primary: { main: '#1976d2' },
//     },
//   },
//   koKR,
// );
export default function MaterialUIPickers(props) {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    props.onSetDate(date);
    setSelectedDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          margin="normal"
          allowKeyboardControl={false}
          animateYearScrolling={false}
          disablePast={false}
          emptyLabel=""
          id="date-picker-dialog"
          format="yyyy-MM-dd"
          value={selectedDate}
          onChange={handleDateChange}
          // showtoolbar={false}
          // KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
          // KeyboardButtonProps={{
          //   'aria-label': 'change date',
          //   backgroundimage: { search },
          // }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
