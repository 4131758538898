import UserList from "views/users/UserList.jsx";
import UserDetail from "views/users/UserDetail.jsx";
import CalendarList from "views/calendar/CalendarList.jsx";
import PetList from "views/pet/PetList.jsx";
import PetDetail from "views/pet/PetDetail.jsx";
import SpeciesList from "views/species/SpeciesList.jsx";
import SpeciesDetail from "views/species/SpeciesDetail.jsx";
import ColorList from "views/color/ColorList.jsx";
import BoardList from "views/board/BoardList.jsx";
import BoardDetail from "views/board/BoardDetail.jsx";
import BreedList from "views/breed/BreedList.jsx";
import BreedDetail from "views/breed/BreedDetail.jsx";
import BreedInfoList from "views/breedInfo/BreedInfoList.jsx";
import BreedInfoDetail from "views/breedInfo/BreedInfoDetail.jsx";
import ColumnList from "views/column/ColumnList.jsx";
import ColumnDetail from "views/column/ColumnDetail.jsx";
import BrandList from "views/brand/BrandList.jsx";
import BrandDetail from "views/brand/BrandDetail.jsx";
import ItemList from "views/item/ItemList.jsx";
import ItemDetail from "views/item/ItemDetail.jsx";
import RecommList from "views/recommendation/RecommList.jsx";
import RecommDetail from "views/recommendation/RecommDetail.jsx";
import PushList from "views/push/PushList.jsx";
import PushDetail from "views/push/PushDetail.jsx";
import NoticeList from "views/notice/NoticeList.jsx";
import NoticeDetail from "views/notice/NoticeDetail.jsx";
import FaqList from "views/faq/FaqList.jsx";
import FaqDetail from "views/faq/FaqDetail.jsx";
import CompanyInfo from "views/companyInfo/CompanyInfo";
import TermList from "views/term/TermList";
import TermDetail from "views/term/TermDetail";
import AdList from "views/ad/AdList";
import AdDetail from "views/ad/AdDetail";
import CommentList from "views/comment/CommentList";
import ReportList from "views/report/ReportList";
import ReportDetail from "views/report/ReportDetail";
import InfoEditList from "views/infoEdit/InfoEditList";
import InfoEditDetail from "views/infoEdit/InfoEditDetail";
import Icons from "views/Icons";

const dashboardRoutes = [
  {
    path: "/userList",
    component: UserList,
    layout: "/admin",
  },
  {
    path: `/user`,
    component: UserDetail,
    layout: "/admin",
  },
  {
    path: "/petList",
    component: PetList,
    layout: "/admin",
  },
  {
    path: `/pet`,
    component: PetDetail,
    layout: "/admin",
  },
  {
    path: "/calendarList",
    component: CalendarList,
    layout: "/admin",
  },
  {
    path: "/brandList",
    component: BrandList,
    layout: "/admin",
  },
  {
    path: `/brand`,
    component: BrandDetail,
    layout: "/admin",
  },
  {
    path: "/itemList",
    component: ItemList,
    layout: "/admin",
  },
  {
    path: `/item`,
    component: ItemDetail,
    layout: "/admin",
  },
  {
    path: "/recommList",
    component: RecommList,
    layout: "/admin",
  },
  {
    path: `/recomm`,
    component: RecommDetail,
    layout: "/admin",
  },
  {
    path: "/pushList",
    component: PushList,
    layout: "/admin",
  },
  {
    path: `/push`,
    component: PushDetail,
    layout: "/admin",
  },
  {
    path: "/speciesList",
    component: SpeciesList,
    layout: "/admin",
  },
  {
    path: `/species`,
    component: SpeciesDetail,
    layout: "/admin",
  },
  {
    path: "/breedList",
    component: BreedList,
    layout: "/admin",
  },
  {
    path: `/breed`,
    component: BreedDetail,
    layout: "/admin",
  },
  {
    path: "/breedInfoList",
    component: BreedInfoList,
    layout: "/admin",
  },
  {
    path: `/breedInfo`,
    component: BreedInfoDetail,
    layout: "/admin",
  },
  {
    path: "/colorList",
    component: ColorList,
    layout: "/admin",
  },
  {
    path: `/columnList`,
    component: ColumnList,
    layout: "/admin",
  },
  {
    path: `/column`,
    component: ColumnDetail,
    layout: "/admin",
  },
  {
    path: `/noticeList`,
    component: NoticeList,
    layout: "/admin",
  },
  {
    path: `/notice`,
    component: NoticeDetail,
    layout: "/admin",
  },
  {
    path: `/faqList`,
    component: FaqList,
    layout: "/admin",
  },
  {
    path: `/faq`,
    component: FaqDetail,
    layout: "/admin",
  },
  {
    path: `/companyInfo`,
    component: CompanyInfo,
    layout: "/admin",
  },
  {
    path: `/termList`,
    component: TermList,
    layout: "/admin",
  },
  {
    path: `/term`,
    component: TermDetail,
    layout: "/admin",
  },
  {
    path: `/adList`,
    component: AdList,
    layout: "/admin",
  },
  {
    path: `/ad`,
    component: AdDetail,
    layout: "/admin",
  },
  {
    path: `/boardList`,
    component: BoardList,
    layout: "/admin",
  },
  {
    path: `/board`,
    component: BoardDetail,
    layout: "/admin",
  },
  {
    path: "/commentList",
    component: CommentList,
    layout: "/admin",
  },
  {
    path: `/report`,
    component: ReportDetail,
    layout: "/admin",
  },
  {
    path: "/reportList",
    component: ReportList,
    layout: "/admin",
  },
  {
    path: `/infoEditList`,
    component: InfoEditList,
    layout: "/admin",
  },
  {
    path: `/infoEdit`,
    component: InfoEditDetail,
    layout: "/admin",
  },

  {
    path: `/icons`,
    component: Icons,
    layout: "/admin",
  },
];

export default dashboardRoutes;
