import axios from "axios";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "token"
)}`;

// 로그인
export async function getLogin(params) {
  try {
    delete axios.defaults.headers.common["Authorization"];
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}/auth/signIn`,
      params,
      axios.defaults.headers.common
    );
    console.log(response);
    return response.data;
  } catch (e) {
    console.log("login-setHeader fail");
    console.log(e);
  }
}

// 카카오지도검색
export async function getGeoCode(params) {
  let path =
    `https://dapi.kakao.com/v2/local/search/address.json?query=` +
    encodeURIComponent(params);
  const response = await axios.get(path, {
    headers: {
      Authorization: "KakaoAK 428e543085a1fa97b01ecaf1cf8fb47b",
      ContentType: "application/json",
    },
  });
  console.log(response);
  return response;
}

// 조회
export async function fetchData(url, params) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;

  console.log(url);
  console.log(localStorage.getItem("token"));
  let path = `${process.env.REACT_APP_API_HOST}/${url}`;

  const response = await axios.get(path, axios.defaults.headers.common);
  console.log(response);

  return response.data;
}

// 단건 조회
export async function getData(url, seq) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_HOST}/${url}/${seq}`,
    axios.defaults.headers.common
  );
  if (response.data.code === -1002) {
    alert(response.data.msg);
  }
  console.log(response.data);
  return response.data.data;
}

// 단건 조회
export async function getData3(url, param) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  const response = await axios.get(
    `${process.env.REACT_APP_API_HOST}/${url}`,
    param,
    axios.defaults.headers.common
  );
  if (response.data.code === -1002) {
    alert(response.data.msg);
  }
  console.log(response.data);
  return response.data.data;
}

// get
export async function getData2(url, params) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_HOST}/${url}?${params}`,
    axios.defaults.headers.common
  );
  if (response.data.code === -1002) {
    alert(response.data.msg);
  }
  return response.data;
}

// 등록
export async function addData(url, params) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;

  const response = await axios.post(
    `${process.env.REACT_APP_API_HOST}/${url}?${params}`,
    axios.defaults.headers.common
  );
  if (response.data.code === -1002) {
    alert(response.data.msg);
  }
  return response.data;
}

// 폼등록
export async function addFormData(url, params) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_HOST}/${url}`,
    params,
    axios.defaults.headers.common
  );
  if (response.data.code === -1002) {
    alert(response.data.msg);
  }
  return response.data;
}

// JSON 등록
export async function addJsonData(url, params) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_HOST}/${url}`,
    params,
    axios.defaults.headers.common
  );
  if (response.data.code === -1002) {
    alert(response.data.msg);
  }
  return response.data;
}

// 수정
export async function editData(url, params) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_HOST}/${url}`,
    JSON.stringify(Object.fromEntries(params)),
    axios.defaults.headers.common
  );
  if (response.data.code === -1002) {
    alert(response.data.msg);
  }
  return response.data;
}

// 폼수정
export async function editFormData(url, params) {
  console.log(axios.defaults.headers.common);
  const response = await axios.put(
    // `http://117.:4001/${url}`,
    // `http://localhost:4001/${url}`,
    `${process.env.REACT_APP_API_HOST}/${url}`,
    params,
    axios.defaults.headers.common
  );

  return response.data;
}

// patch
export async function editPatchData(url, params) {
  console.log(axios.defaults.headers.common);
  const response = await axios.patch(
    `${process.env.REACT_APP_API_HOST}/${url}`,
    params,
    axios.defaults.headers.common
  );
  console.log(response);

  return response.data;
}

// 삭제
export async function deleteData(url, params) {
  // axios.defaults.headers.common[
  //   "Authorization"
  // ] = `Bearer ${localStorage.getItem("token")}`;

  const response = await axios.delete(
    `${process.env.REACT_APP_API_HOST}/${url}/${params}`,
    axios.defaults.headers.common
  );
  return response.data;
}

// 로그인
export async function getSnsLogin(params) {
  try {
    delete axios.defaults.headers.common["Authorization"];
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}/api/sns-login`,
      params,
      axios.defaults.headers.common
    );
    console.log(response);
    return response.data;
  } catch (e) {
    console.log("login-setHeader fail");
    console.log(e);
  }
}

// 파일 단일 등록
export async function addFileUpload(url, params) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_HOST}/${url}`,
    params,
    axios.defaults.headers.common
  );
  if (response.data.code === -1002) {
    alert(response.data.msg);
  }
  return response.data;
}
