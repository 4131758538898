import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  // FormGroup,
  // ControlLabel,
  // FormControl,
  // Modal,
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { useHistory } from "react-router-dom";
// import { UserCard } from "views/users/node_modules/components/UserCard/UserCard.jsx.js";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  fetchData,
  editFormData,
  getData,
  addFormData,
  addData,
  deleteData,
} from "modules/api.js";
import { useStyles } from "./style.jsx";
import Moment from "moment";
import { SelectionState } from "draft-js";

function BreedDetail(props) {
  const detailType = {
    nameKr: null,
    nameEn: null,
    subtitle: null,
    speciesId: null,
  };
  const [input, setInput] = useState();
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState();
  const [fileList, setFileList] = useState([]);
  const [speciesList, setSpeciesList] = useState([]);
  const reload = () => {
    if (props.match.params.value !== "add") {
      (async function anyNameFunction() {
        const result = await fetchData(`user/speciesList`);
        setSpeciesList(result.data);
      })();
      (async function anyNameFunction() {
        const result = await fetchData(
          `admin/breed/${props.match.params.value}`
        );
        // setGalleryList(result.data.gallery);
        if (result.data.introImg) {
          result.data.introImg_path = result.data.introImg;
        }
        if (result.data.profileImg) {
          result.data.profileImg_path = result.data.profileImg;
        }
        setInput(result.data);
        setData(result.data);
      })();
    } else {
      (async function anyNameFunction() {
        const result = await fetchData(`user/speciesList`);
        setSpeciesList(result.data);
      })();
      setInput(detailType);
    }
  };
  const changeGalleryImage = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      // const newList = fileList.concat(file);
      const newItem = {
        files: file,
        image: reader.result,
      };
      console.log(fileList.concat(newItem));
      setFileList(fileList.concat(newItem));
    };
    reader.readAsDataURL(file);
  };

  const deleteImage = (e) => {
    const newList = fileList.filter((item, index) => index != e);
    setFileList(newList);
  };

  const deteleSavedImage = (e) => {
    if (window.confirm("삭제하시겠습니까?")) {
      deleteData(`admin/gallery`, e).then(function (response) {
        console.log(response);
        if (response.result) {
          alert("삭제성공");
          reload();
          const newList = data.gallery.filter((item, index) => index != e);
          console.log(newList);
          setData({ ...data, gallery: newList });
        } else {
          alert("삭제실패");
        }
      });
    }
  };
  const onAdd = () => {
    console.log(input);
    if (!input.speciesId || input.speciesId == "") {
      alert("종 번호를 입력해주세요");
      return false;
    }
    if (!input.nameKr || input.nameKr == "") {
      alert("국문명을 입력해주세요");
      return false;
    }
    if (!input.nameEn || input.nameEn == "") {
      alert("영문명을 입력해주세요");
      return false;
    }
    if (!input.subtitle || input.subtitle === "") {
      alert("소개말을 입력해주세요");
      return false;
    }
    if (!input.profileImg || input.profileImg == "") {
      alert("대표 이미지를 입력해주세요");
      return false;
    }
    if (!input.introImg || input.introImg == "") {
      alert("인트로 이미지를 입력해주세요");
      return false;
    }

    const bodyFormData = new FormData();
    bodyFormData.set("speciesId", input.speciesId);
    bodyFormData.set("nameKr", input.nameKr);
    bodyFormData.set("nameEn", input.nameEn);
    bodyFormData.set("profileImg", input.profileImg);
    bodyFormData.set("introImg", input.introImg);
    bodyFormData.set("subtitle", input.subtitle);

    addFormData(`admin/breed`, bodyFormData).then(function (response) {
      console.log(response);
      if (response.result) {
        alert("품종 등록이 완료되었습니다.");
        history.push(`/admin/breedList/1`);
      } else {
        alert(response);
      }
    });
  };

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const changeProfileImage = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        profileImg: file,
        profileImg_path: reader.result,
      });
    };
    reader.readAsDataURL(file);
    console.log(input);
  };

  const changeIntroImage = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        introImg: file,
        introImg_path: reader.result,
      });
    };
    reader.readAsDataURL(file);
    console.log(input);
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
      // return false;
    }
    reload();
  }, []);

  const onSubmit = () => {
    if (!input.nameKr || input.nameKr === "") {
      alert("국문명을 입력해주세요");
      return false;
    }
    if (!input.nameEn || input.nameEn === "") {
      alert("영문명을 입력해주세요");
      return false;
    }
    if (!input.subtitle || input.subtitle === "") {
      alert("소개말을 입력해주세요");
      return false;
    }
    if (!input.profileImg || input.profileImg == "") {
      alert("대표 이미지를 입력해주세요");
      return false;
    }
    if (!input.introImg || input.introImg == "") {
      alert("인트로 이미지를 입력해주세요");
      return false;
    }

    console.log(input);

    const bodyFormData = new FormData();
    bodyFormData.set("id", input.id);
    bodyFormData.set("nameKr", input.nameKr);
    bodyFormData.set("nameEn", input.nameEn);
    bodyFormData.set("profileImg", input.profileImg);
    bodyFormData.set("introImg", input.introImg);
    bodyFormData.set("subtitle", input.subtitle);
    bodyFormData.set("speciesId", input.speciesId);

    editFormData(`admin/breed`, bodyFormData).then(function (response) {
      console.log(response);
      if (response.result) {
        if (fileList.length > 0) {
          const newList = fileList.map((item) => {
            return item.files;
          });
          const bodyFormData = new FormData();
          bodyFormData.set("type", "breed");
          bodyFormData.set("contentsId", input.id);

          for (var i = 0; i < newList.length; i++) {
            bodyFormData.append("files", newList[i]);
          }
          addFormData(`admin/gallery`, bodyFormData).then(function (response) {
            if (response.result) {
              console.log(response.data);
            }
          });
        }
        alert("품종정보 수정이 완료되었습니다.");
        history.push(`/admin/breedList/1`);
      } else {
        alert(response);
      }
    });
  };

  const onRemove = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      //품종 없을때만 삭제시키기
      deleteData(`admin/breed`, input.id).then(function (response) {
        if (response.result) {
          alert("삭제성공");
          history.push(`/admin/breedList/1`);
        } else {
          alert(response.msg);
          return false;
        }
      });
    }
  };

  return (
    <div className="content">
      <Grid fluid>
        {input && (
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col md={7}>
              <Card
                title={
                  (props.match.params.value !== "add" && "품종 상세정보") ||
                  (props.match.params.value === "add" && "품종 등록")
                }
                content={
                  <form onSubmit={onSubmit}>
                    {props.match.params.value !== "add" && (
                      <>
                        <FormInputs
                          ncols={["col-md-4", "col-md-4", "col-md-4"]}
                          properties={[
                            {
                              label: "번호",
                              type: "text",
                              bsClass: "form-control",
                              name: "id",
                              disabled: true,
                              value: input.id || "",
                              style: { cursor: "initial" },
                            },
                            {
                              label: "종 분류",
                              type: "select",
                              bsClass: "form-control",
                              name: "speciesId",
                              className: "infoSelect",
                              // disabled: true,
                              onChange: (e) => handleInputChange(e),
                              value: input.speciesId || "",
                              style: { cursor: "initial" },
                              option: (
                                <>
                                  {speciesList &&
                                    speciesList.map((item, index) => {
                                      return (
                                        <option
                                          value={item.id}
                                          key={`species${item.id}`}
                                        >
                                          {item.name_kr}
                                        </option>
                                      );
                                    })}
                                </>
                              ),
                            },
                            {
                              label: "등록일",
                              type: "text",
                              bsClass: "form-control",
                              name: "createdAt",
                              disabled: true,
                              onChange: (e) => handleInputChange(e),
                              value: input.createdAt
                                ? Moment(input.createdAt).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                : "",
                              style: { cursor: "initial" },
                            },
                          ]}
                        />

                        <FormInputs
                          ncols={["col-md-6", "col-md-6"]}
                          properties={[
                            {
                              label: "국문명",
                              type: "text",
                              bsClass: "form-control",
                              name: "nameKr",
                              // disabled: true,
                              onChange: (e) => handleInputChange(e),
                              value: input.nameKr || "",
                              style: { cursor: "initial" },
                            },

                            {
                              label: "영문명",
                              type: "text",
                              bsClass: "form-control",
                              name: "nameEn",
                              // disabled: true,
                              onChange: (e) => handleInputChange(e),
                              value: input.nameEn || "",
                              style: { cursor: "initial" },
                            },
                          ]}
                        />
                      </>
                    )}
                    {props.match.params.value === "add" && (
                      <>
                        <FormInputs
                          ncols={["col-md-4", "col-md-4", "col-md-4"]}
                          properties={[
                            {
                              label: "종 분류",
                              type: "select",
                              bsClass: "form-control",
                              name: "speciesId",
                              className: "infoSelect",
                              // disabled: true,
                              onChange: (e) => handleInputChange(e),
                              value: input.speciesId || "",
                              style: { cursor: "initial" },
                              option: (
                                <>
                                  {speciesList &&
                                    speciesList.map((item, index) => {
                                      return (
                                        <option
                                          value={item.id}
                                          key={`species${item.id}`}
                                        >
                                          {item.name_kr}
                                        </option>
                                      );
                                    })}
                                </>
                              ),
                            },
                            {
                              label: "국문명",
                              type: "text",
                              bsClass: "form-control",
                              name: "nameKr",
                              // disabled: true,
                              onChange: (e) => handleInputChange(e),
                              value: input.nameKr || "",
                              style: { cursor: "initial" },
                            },

                            {
                              label: "영문명",
                              type: "text",
                              bsClass: "form-control",
                              name: "nameEn",
                              // disabled: true,
                              onChange: (e) => handleInputChange(e),
                              value: input.nameEn || "",
                              style: { cursor: "initial" },
                            },
                          ]}
                        />
                      </>
                    )}
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "소개말",
                          type: "text",
                          bsClass: "form-control",
                          name: "subtitle",
                          // readOnly: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.subtitle || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    {props.match.params.value === "add" && (
                      <Button
                        bsStyle="info"
                        style={{ marginLeft: "1%" }}
                        pullRight
                        fill
                        onClick={(e) => onAdd(e)}
                      >
                        등록
                      </Button>
                    )}
                    {props.match.params.value !== "add" && (
                      <>
                        <Button
                          bsStyle="info"
                          style={{ marginLeft: "1%" }}
                          pullRight
                          fill
                          onClick={onSubmit}
                        >
                          수정
                        </Button>
                        <Button
                          bsStyle="warning"
                          style={{ marginLeft: "1%" }}
                          pullRight
                          fill
                          onClick={onRemove}
                        >
                          삭제
                        </Button>
                        <Button
                          bsStyle="success"
                          style={{ marginLeft: "1%" }}
                          pullRight
                          fill
                          onClick={(e) => history.goBack()}
                        >
                          목록
                        </Button>
                      </>
                    )}
                    <div className="clearfix" />
                  </form>
                }
              />
              <Card
                title="갤러리 이미지"
                content={
                  <>
                    <label
                      className="fileInputStyle"
                      htmlFor="file-input"
                      style={{
                        color: "rgba(0, 0, 0, 0.87)",
                        marginBottom: "15px",
                        fontSize: "14px",
                        display: "table",
                      }}
                    >
                      업로드
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      name="profileImage"
                      style={{ marginBottom: "10px", display: "none" }}
                      onChange={changeGalleryImage}
                    />
                    {data &&
                      data.gallery &&
                      data.gallery.length > 0 &&
                      data.gallery.map((item, index) => {
                        console.log(item);
                        return (
                          <i
                            className="listImage"
                            key={`saved${item.id}`}
                            style={{ position: "relative" }}
                          >
                            <img
                              style={{
                                borderRadius: "5px",
                                verticalAlign: "middle",
                                width: "175px",
                                height: "175px",
                                margin: "10px 10px",
                              }}
                              src={
                                item.image &&
                                item.image != "null" &&
                                item.image != "undefined"
                                  ? item.image
                                  : ""
                              }
                            />
                            <button
                              className="deleteImageButton"
                              onClick={(e) => deteleSavedImage(item.id)}
                            >
                              ×
                            </button>
                          </i>
                        );
                      })}

                    {fileList &&
                      fileList.map((item, index) => {
                        return (
                          <i
                            className="listImage"
                            key={index}
                            style={{ position: "relative" }}
                          >
                            <img
                              style={{
                                borderRadius: "5px",
                                verticalAlign: "middle",
                                width: "175px",
                                height: "175px",
                                margin: "10px 10px",
                              }}
                              src={
                                item.image &&
                                item.image != "null" &&
                                item.image != "undefined"
                                  ? item.image
                                  : ""
                              }
                            />
                            <button
                              className="deleteImageButton"
                              onClick={(e) => deleteImage(index)}
                            >
                              ×
                            </button>
                          </i>
                        );
                      })}
                  </>
                }
              />
            </Col>
            <Col md={3}>
              {input && (
                <>
                  <Card
                    title="대표 이미지"
                    content={
                      <>
                        <label
                          className="fileInputStyle"
                          htmlFor="fileInput1"
                          style={{
                            color: "rgba(0, 0, 0, 0.87)",
                            marginBottom: "15px",
                            fontSize: "14px",
                          }}
                        >
                          업로드
                        </label>
                        <input
                          id="fileInput1"
                          type="file"
                          name="files"
                          style={{ marginBottom: "10px", display: "none" }}
                          onChange={changeProfileImage}
                          accept=".jpg, .jpeg, .png, .bmp"
                        />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            style={{
                              maxWidth: "90%",
                              maxHeight: "350px",
                              borderRadius: "5px",
                              verticalAlign: "middle",
                            }}
                            src={input.profileImg_path || ""}
                          />
                        </div>
                      </>
                    }
                  />
                  <Card
                    title="인트로 이미지"
                    content={
                      <>
                        <label
                          className="fileInputStyle"
                          htmlFor="fileInput2"
                          style={{
                            color: "rgba(0, 0, 0, 0.87)",
                            marginBottom: "15px",
                            fontSize: "14px",
                          }}
                        >
                          업로드
                        </label>
                        <input
                          id="fileInput2"
                          type="file"
                          name="files"
                          style={{ marginBottom: "10px", display: "none" }}
                          onChange={changeIntroImage}
                          accept=".jpg, .jpeg, .png, .bmp"
                        />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            style={{
                              maxWidth: "90%",
                              maxHeight: "350px",
                              borderRadius: "5px",
                              verticalAlign: "middle",
                            }}
                            src={input.introImg_path || ""}
                          />
                        </div>
                      </>
                    }
                  />
                </>
              )}
            </Col>
          </Row>
        )}
      </Grid>

      {/* <Modal
        show={addressModalShow}
        onHide={() => setAddressModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered="true"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>
        <Modal.Body>
          <DaumPostcode onComplete={handleComplete} key="daum" />
        </Modal.Body>
      </Modal> */}
    </div>
  );
}

export default BreedDetail;
